#contact {
  
}

#contact {
	margin-top: 0 !important;
  background-color: rgba(8, 49, 92, .7) !important;
  padding: 100px 50px 100px 50px !important;
  text-align: center;
	color: #fff;
    a {
        color: $black_2;
        font-weight: 400;
    }
    .card {
      color:rgba(0, 0, 0, 0.8) !important;
      margin-right: 20px;
      &:nth-child(4) {
        margin-right: 0;
      }
      .card-body {
        padding: 1rem 0.3rem;
      }
      
    }
}
#contact .section-title {
	margin-bottom: 40px;
}
#contact .section-title p {
	font-size: 16px;
}
#contact h2 {
	// color: $black_2;
	margin-bottom: 15px;
	padding-bottom: 15px;
}
#contact .section-title h2::after {
	// position: absolute;
	content: "";
	// background: rgba(255,255,255,.3);
	height: 4px;
	width: 60px;
	bottom: 0;
	// left: 30px;
}
#contact h3 {
	color: #fff;
	margin-top: 80px;
	margin-bottom: 25px;
	padding-bottom: 20px;
	font-weight: 400;
}
#contact form {
	padding-top: 20px;
}
#contact .text-danger {
	color: #cc0033;
	text-align: left;
}
#contact .btn-custom {
	margin: 30px 0;
	background: $green_default;
	border: 1px solid #fff;
}
#contact .btn-custom:hover {
	color: #1f386e;
	background: #fff;
}
label {
	font-size: 12px;
	font-weight: 400;
	font-family: 'Open Sans', sans-serif;
	float: left;
}
#contact .form-control {
	display: block;
	width: 100%;
	padding: 6px 12px;
	font-size: 16px;
	line-height: 1.42857143;
	color: #444;
	background-color: #fff;
	background-image: none;
	border: 1px solid #ddd;
	border-radius: 0;
	-webkit-box-shadow: none;
	box-shadow: none;
	-webkit-transition: none;
	-o-transition: none;
	transition: none;
}
#contact .form-control:focus {
	border-color: #999;
	outline: 0;
	-webkit-box-shadow: transparent;
	box-shadow: transparent;
}
.form-control::-webkit-input-placeholder {
color: #777;
}
.form-control:-moz-placeholder {
color: #777;
}
.form-control::-moz-placeholder {
color: #777;
}
.form-control:-ms-input-placeholder {
color: #777;
}
#contact .contact-item {
	margin: 60px 0;
}
#contact .contact-item span {
	color: rgba(255,255,255,1);
	margin-bottom: 10px;
	display: block;
}
#contact .contact-item i.fas {
	margin-right: 10px;
}
#contact .social {
	margin: 30px;
	text-align: center;
}
#contact .social ul li {
	display: inline-block;
	margin: 0 20px;
}
#contact .social i.fab {
	font-size: 22px;
	width: 48px;
	height: 48px;
	padding: 12px 0;
	border: 2px solid $black_2;
	color: $black_2;
	border-radius: 50%;
	transition: all 0.3s;
}
#contact .social i.fab:hover {
	color: $white_1;
	background: #608dfd;
  border: 2px solid #608dfd;
}
#contact a {
	color: #ffffff;
	transition: all .5s;
	&:hover {
		color: #a2cc39;
		transition: all .5s;
	}
}