@import '../../../../scss/colors';

#roadmap {
  margin-top: 0px !important;
  padding: 100px 50px 100px 50px !important;
  background-color: rgba(8, 49, 92, .3) !important;
	.card {
		display: table-cell;
		background-color: rgba(255,255,255, .5) !important;
		transition: all .25s;
		cursor: pointer;
		border-width: 0;
		&:hover {
			background-color: rgba(255,255,255, 1) !important;
			transition: all .25s;
		}
		.card-body {
			padding-bottom: 30px;
			z
			li:not(:last-child) {
				padding-bottom: 15px;
			}
		}
		.card-footer {
			position: absolute;
			display: table-cell;
			bottom: 0;
			width: 100%;
			&.success {
				background: $green-4;
				color: $white_1 !important;
			}
			&.progress {
				// bottom: 25px;
				// height: 100%;
				background: $chart-basic2-4;
				color: $white_1 !important;
				top: calc(100% - 40px);
   		  height: 41px;
				font-size: 16px;
			}
		}
	}
}
#roadmap i.fa {
	font-size: 48px;
	margin-bottom: 20px;
}
#roadmap .card-title {
	// font-size: 28px;
	// margin: 0 auto;
	margin-bottom: 20px;
	transition: all 0.5s;
	// color: #fff;
	// width: 100px;
	// height: 100px;
	padding: 30px 0;
	background: #08315c;
	color: #fff;
	// border-radius: 50%;
	// background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);
	// box-shadow: 10px 10px 10px rgba(0,0,0,.05);
	border-top-right-radius: 6px;
	border-top-left-radius: 6px;
	
	h3 {
		font-size: 28px;
		margin: 0 auto;
		// margin-bottom: 20px;
		transition: all 0.5s;
	}
}

.roadmap {
		display: table;
		font-weight: bold;
	
}