$grey_1: #A9A9A9;
$grey_2: #DBDBDB;
$grey_3: #F5F5F5;

$grey_4: #687075;
$grey_5: #7D7D7D;

$white_1: #ffffff;
$white_2: rgba(255, 255, 255, 0.3);

$black_1: #000000;
$black_2: #28343A;

$blue-1: #007DA1;
$blue-2: #2aa4c5;
$blue-3: #56c8e7;
$blue_default: #08315c;
$blue_default_2: #105daf;
$blue-link: #0b5ed7;

// Mono
$blue-4: #2aa4c5;
$blue-5: #1d7c8a;
$blue-6: #1d7c8a;
$blue-7: #9bf2ff;

// Analogic
$blue-8: #2aa4c5;
$blue-9: #1d7c8a;
$blue-10: #304dc5;

$green-1: #2ac53f;
$green-3: #1d8a2c;

$green-2: #c6ffc6;
$green-3: #8dff8d;

$green-4: #6d8a1d;
$green-5: #4c6114;
$green-6:#39ef79;
$green-7:#16bc4b;
$green-default: #a2cc39;
$green-default-hover: #c3eb5f;


$purple-1: #8a1d65;
$purple-2: #541d8a;
$purple-3: #3b1461;
$purple-4: #611459;


// Triad
$blue-11: #2aa4c5;
$blue-12: #1d7c8a;
// $blue-3: #c52a90;
// $blue-3: #8a1d65;
// $blue-3: #c5aa2a;

// Tetrade
$blue-13: #2a83cc;
$brown-3: #c56c2a;
$brown-3: #8a4c1d;
$blue-14: #473ac5;
$blue-15: #32298a;
$blue-15: #5976b3;

$red-1: #b30000;
$red-2: #ff0000;

// Cart Colors
// Light triad
$chart-triadblue-1: #7982B9;
$chart-triadblue-2: #A5C1DC;
$chart-triadblue-3: #E9F6FA;

// Dark-Light blue
$chart-darkblue-1: #00008B;
$chart-darkblue-2: #1F75FE;
$chart-darkblue-3: #74BBFB;

// Green Flat
$chart-greenflat-1: #64C2A6;
$chart-greenflat-2: #AADEA7;
$chart-greenflat-3: #E6F69D;

// Orange Simple
$chart-orangesimple-1: #EC6B56;
$chart-orangesimple-2: #FFC154;
$chart-orangesimple-3: #47B39C;

// Basic Simple
$chart-basic-1: #003F5C;
$chart-basic-2: #58508D;
$chart-basic-3: #BC5090;
$chart-basic-4: #FF6361;
$chart-basic-5: #FFA600;

// Basic Simple 2
$chart-basic2-1: #3C9D4E;
$chart-basic2-2: #7031AC;
$chart-basic2-3: #C94D6D;
$chart-basic2-4: #E4BF58;
$chart-basic2-5: #4174C9;
// Basic Light
$chart-light-1: #9BBFE0;
$chart-light-2: #E8A09A;
$chart-light-3: #FBE29F;
$chart-light-4: #C6D68F;
