#about {
  margin-top: 0 !important;
  background-color: rgba(8, 49, 92, .5) !important;
  padding: 100px 50px 100px 50px;
  // text-align: center;
	color: #fff;
}
#about {
	.col-md-6 {
		display: table;
	}
  color: #fff;
  h1, .token, .about-text h4, h4 {
    color: #fff;
  }
  .card, .card-body {
		display: table-cell;
		border: none;
    font-size: .9rem;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.8) !important;
  }
}
/* About Section */
#about .section-title h2::after {
	position: absolute;
	content: "";
	height: 4px;
	width: 60px;
	bottom: 0;
	margin-left: -30px;
	left: 50%;
}

#about h3 {
	font-size: 22px;
	margin: 0 auto;
	padding-top: 20px;
	text-align: center;
}
#about h2 {
	text-align: center;
	margin: auto;
	padding-bottom: 15px;
}
#about .about-text li {
	margin-bottom: 6px;
	margin-left: 6px;
	list-style: none;
	padding: 0;
}
#about .about-text li i:before {
	color: #5ca9fb;
	font-size: 20px;
	font-weight: 300;
	padding-right: 0px;
}
#about img {
	// width: 520px;
	max-width: 100%;
	// margin-top: 10px;
	// background: #fff;
	// border-right: 0;
	// box-shadow: 0 0 50px rgba(0,0,0,0.06);
}
#about p {
	line-height: 24px;
	margin: 30px 0;
}

#about i.fa, #about i.fas {
	font-size: 38px;
	margin-bottom: 20px;
	transition: all 0.5s;
	color: #fff;
	width: 100px;
	height: 100px;
	padding: 30px 0;
	border-radius: 50%;
	background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);
	box-shadow: 10px 10px 10px rgba(0,0,0,.05);
}

#about .col-md-6 {
	display: table;
	.card {
		display: table-cell;
		background: rgba(1255,255,255, .3) !important;
		border: none;
		color: #fff;
		transition: all .25s;
		cursor: pointer;
		&:hover {
			background: rgba(255,255,255, .7) !important;
			transition: all .25s;
			color: rgba(0,0,0, .85) !important;
			
		}
	}
}

.about-btn {
	margin-top: 100px;
	text-align: center;
}