// Login
.login {
  height: 100vh;
  // width: 100vw;
  margin-top: -100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 300px;
}
.login__container {
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: #dcdcdc;
  padding: 30px;
  width: 400px;
}
.login__textBox {
  padding: 20px !important;
  font-size: 16px !important;
  margin-bottom: 10px;
}
.login__btn {
  padding: 10px;
  font-size: 18px;
  margin-bottom: 10px;
  border: none;
  color: white;
  background-color: black;
}
.login__google {
  background-color: #4285f4;
}
.login div {
  margin-top: 7px;
}

// Register
.register {
  height: 100vh;
  // width: 100vw;
  margin-top: -100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 100px;
}
.register__container {
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: #dcdcdc;
  padding: 30px;
  width: 400px;
}
.register__textBox {
  padding: 20px !important;
  font-size: 16px !important;
  margin-bottom: 10px;
}
.register__btn , .login__btn, .reset__btn{
  padding: 10px;
  font-size: 16px !important;
  margin-bottom: 10px;
  border: none;
  color: white;
  background-color: #4285f4;
  margin-bottom: 10px;
  padding: 10px;
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #fff;
  // background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.675rem;
  line-height: 1.5;
  border-radius: 0rem;
}
.register__google {
  background-color: #4285f4;
}
.register div {
  margin-top: 7px;
  font-size: 14px;
}

// Reset
.reset {
  height: 100vh;
  // width: 100vw;
  margin-top: -100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 300px;
}
.reset__container {
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: #dcdcdc;
  padding: 30px;
  width: 400px;
}
.reset__textBox {
  padding: 20px !important;
  font-size: 16px !important;
  margin-bottom: 10px;
}
// .reset__btn {
//   padding: 10px;
//   font-size: 18px;
//   margin-bottom: 10px;
//   border: none;
//   color: white;
//   background-color: black;
// }
.reset div {
  margin-top: 7px;
}

// Dashboard
.dashboard {
  height: 100vh;
  // width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dashboard__container {
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: #dcdcdc;
  padding: 30px;
}
.dashboard__btn {
  padding: 10px;
  font-size: 18px;
  margin-top: 10px;
  border: none;
  color: white;
  background-color: black;
}
.dashboard div {
  margin-top: 7px;
}

.register__textBox, .login__textBox, .reset__textBox {
  // font-size: 18px;
  margin-bottom: 10px;
  padding: 10px;
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.675rem;
  line-height: 1.5;
  border-radius: 0rem;
  transition: all .25s;
  &:focus {
    border: solid 1px #a2a4a5 !important;
    box-shadow: none !important;
    transition: all .25s;
  }
}

.register__container, .login__container, .reset__container {
  background-color: #dcdcdc;
  display: flex;
  flex-direction: column;
  padding: 30px;
  text-align: center;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  font-size: .675rem;
}
h5 {
  font-size: 1rem;
  margin-bottom: 25px;
}