/* Margin Pixels */

.m0 {
  margin: 0 !important;
}

.m1 {
  margin: 1px !important;
}

.m2 {
  margin: 2px !important;
}

.m3 {
  margin: 3px !important;
}

.m4 {
  margin: 4px !important;
}

.m5 {
  margin: 5px !important;
}

.m6 {
  margin: 6px !important;
}

.m7 {
  margin: 7px !important;
}

.m8 {
  margin: 8px !important;
}

.m9 {
  margin: 9px !important;
}

.m10 {
  margin: 10px !important;
}

.m12 {
  margin: 12px !important;
}

.m14 {
  margin: 14px !important;
}

.m15 {
  margin: 15px !important;
}

.m16 {
  margin: 16px !important;
}

.m18 {
  margin: 18px !important;
}

.m20 {
  margin: 20px !important;
}

.m22 {
  margin: 22px !important;
}

.m24 {
  margin: 24px !important;
}

.m25 {
  margin: 25px !important;
}

.m26 {
  margin: 26px !important;
}

.m28 {
  margin: 28px !important;
}

.m30 {
  margin: 30px !important;
}

.m32 {
  margin: 32px !important;
}

.m34 {
  margin: 34px !important;
}

.m35 {
  margin: 35px !important;
}

.m36 {
  margin: 36px !important;
}

.m38 {
  margin: 38px !important;
}

.m40 {
  margin: 40px !important;
}

.m42 {
  margin: 42px !important;
}

.m44 {
  margin: 44px !important;
}

.m45 {
  margin: 45px !important;
}

.m46 {
  margin: 46px !important;
}

.m48 {
  margin: 48px !important;
}

.m50 {
  margin: 50px !important;
}

.m55 {
  margin: 55px !important;
}

.m60 {
  margin: 60px !important;
}

.m65 {
  margin: 65px !important;
}

.m70 {
  margin: 70px !important;
}

.m75 {
  margin: 75px !important;
}

.m80 {
  margin: 80px !important;
}

.m85 {
  margin: 85px !important;
}

.m90 {
  margin: 90px !important;
}

.m95 {
  margin: 95px !important;
}

.m100 {
  margin: 100px !important;
}

/** Margin-Pixels-Top **/

.mt0 {
  margin-top: 0 !important;
}

.mt1 {
  margin-top: 1px !important;
}

.mt2 {
  margin-top: 2px !important;
}

.mt3 {
  margin-top: 3px !important;
}

.mt4 {
  margin-top: 4px !important;
}

.mt5 {
  margin-top: 5px !important;
}

.mt6 {
  margin-top: 6px !important;
}

.mt7 {
  margin-top: 7px !important;
}

.mt8 {
  margin-top: 8px !important;
}

.mt9 {
  margin-top: 9px !important;
}

.mt10 {
  margin-top: 10px !important;
}

.mt12 {
  margin-top: 12px !important;
}

.mt14 {
  margin-top: 14px !important;
}

.mt15 {
  margin-top: 15px !important;
}

.mt16 {
  margin-top: 16px !important;
}

.mt18 {
  margin-top: 18px !important;
}

.mt20 {
  margin-top: 20px !important;
}

.mt22 {
  margin-top: 22px !important;
}

.mt24 {
  margin-top: 24px !important;
}

.mt25 {
  margin-top: 25px !important;
}

.mt26 {
  margin-top: 26px !important;
}

.mt28 {
  margin-top: 28px !important;
}

.mt30 {
  margin-top: 30px !important;
}

.mt32 {
  margin-top: 32px !important;
}

.mt34 {
  margin-top: 34px !important;
}

.mt35 {
  margin-top: 35px !important;
}

.mt36 {
  margin-top: 36px !important;
}

.mt38 {
  margin-top: 38px !important;
}

.mt40 {
  margin-top: 40px !important;
}

.mt42 {
  margin-top: 42px !important;
}

.mt44 {
  margin-top: 44px !important;
}

.mt45 {
  margin-top: 45px !important;
}

.mt46 {
  margin-top: 46px !important;
}

.mt48 {
  margin-top: 48px !important;
}

.mt50 {
  margin-top: 50px !important;
}

.mt55 {
  margin-top: 55px !important;
}

.mt60 {
  margin-top: 60px !important;
}

.mt65 {
  margin-top: 65px !important;
}

.mt70 {
  margin-top: 70px !important;
}

.mt75 {
  margin-top: 75px !important;
}

.mt80 {
  margin-top: 80px !important;
}

.mt85 {
  margin-top: 85px !important;
}

.mt90 {
  margin-top: 90px !important;
}

.mt95 {
  margin-top: 95px !important;
}

.mt100 {
  margin-top: 100px !important;
}

/** Margin-Pixels-Right **/

.mr0 {
  margin-right: 0 !important;
}

.mr1 {
  margin-right: 1px !important;
}

.mr2 {
  margin-right: 2px !important;
}

.mr3 {
  margin-right: 3px !important;
}

.mr4 {
  margin-right: 4px !important;
}

.mr5 {
  margin-right: 5px !important;
}

.mr6 {
  margin-right: 6px !important;
}

.mr7 {
  margin-right: 7px !important;
}

.mr8 {
  margin-right: 8px !important;
}

.mr9 {
  margin-right: 9px !important;
}

.mr10 {
  margin-right: 10px !important;
}

.mr12 {
  margin-right: 12px !important;
}

.mr14 {
  margin-right: 14px !important;
}

.mr15 {
  margin-right: 15px !important;
}

.mr16 {
  margin-right: 16px !important;
}

.mr18 {
  margin-right: 18px !important;
}

.mr20 {
  margin-right: 20px !important;
}

.mr22 {
  margin-right: 22px !important;
}

.mr24 {
  margin-right: 24px !important;
}

.mr25 {
  margin-right: 25px !important;
}

.mr26 {
  margin-right: 26px !important;
}

.mr28 {
  margin-right: 28px !important;
}

.mr30 {
  margin-right: 30px !important;
}

.mr32 {
  margin-right: 32px !important;
}

.mr34 {
  margin-right: 34px !important;
}

.mr35 {
  margin-right: 35px !important;
}

.mr36 {
  margin-right: 36px !important;
}

.mr38 {
  margin-right: 38px !important;
}

.mr40 {
  margin-right: 40px !important;
}

.mr42 {
  margin-right: 42px !important;
}

.mr44 {
  margin-right: 44px !important;
}

.mr45 {
  margin-right: 45px !important;
}

.mr46 {
  margin-right: 46px !important;
}

.mr48 {
  margin-right: 48px !important;
}

.mr50 {
  margin-right: 50px !important;
}

.mr55 {
  margin-right: 55px !important;
}

.mr60 {
  margin-right: 60px !important;
}

.mr65 {
  margin-right: 65px !important;
}

.mr70 {
  margin-right: 70px !important;
}

.mr75 {
  margin-right: 75px !important;
}

.mr80 {
  margin-right: 80px !important;
}

.mr85 {
  margin-right: 85px !important;
}

.mr90 {
  margin-right: 90px !important;
}

.mr95 {
  margin-right: 95px !important;
}

.mr100 {
  margin-right: 100px !important;
}

/** Margin-Pixels-Bottom **/

.mb0 {
  margin-bottom: 0 !important;
}

.mb1 {
  margin-bottom: 1px !important;
}

.mb2 {
  margin-bottom: 2px !important;
}

.mb3 {
  margin-bottom: 3px !important;
}

.mb4 {
  margin-bottom: 4px !important;
}

.mb5 {
  margin-bottom: 5px !important;
}

.mb6 {
  margin-bottom: 6px !important;
}

.mb7 {
  margin-bottom: 7px !important;
}

.mb8 {
  margin-bottom: 8px !important;
}

.mb9 {
  margin-bottom: 9px !important;
}

.mb10 {
  margin-bottom: 10px !important;
}

.mb12 {
  margin-bottom: 12px !important;
}

.mb14 {
  margin-bottom: 14px !important;
}

.mb15 {
  margin-bottom: 15px !important;
}

.mb16 {
  margin-bottom: 16px !important;
}

.mb18 {
  margin-bottom: 18px !important;
}

.mb20 {
  margin-bottom: 20px !important;
}

.mb22 {
  margin-bottom: 22px !important;
}

.mb24 {
  margin-bottom: 24px !important;
}

.mb25 {
  margin-bottom: 25px !important;
}

.mb26 {
  margin-bottom: 26px !important;
}

.mb28 {
  margin-bottom: 28px !important;
}

.mb30 {
  margin-bottom: 30px !important;
}

.mb32 {
  margin-bottom: 32px !important;
}

.mb34 {
  margin-bottom: 34px !important;
}

.mb35 {
  margin-bottom: 35px !important;
}

.mb36 {
  margin-bottom: 36px !important;
}

.mb38 {
  margin-bottom: 38px !important;
}

.mb40 {
  margin-bottom: 40px !important;
}

.mb42 {
  margin-bottom: 42px !important;
}

.mb44 {
  margin-bottom: 44px !important;
}

.mb45 {
  margin-bottom: 45px !important;
}

.mb46 {
  margin-bottom: 46px !important;
}

.mb48 {
  margin-bottom: 48px !important;
}

.mb50 {
  margin-bottom: 50px !important;
}

.mb55 {
  margin-bottom: 55px !important;
}

.mb60 {
  margin-bottom: 60px !important;
}

.mb65 {
  margin-bottom: 65px !important;
}

.mb70 {
  margin-bottom: 70px !important;
}

.mb75 {
  margin-bottom: 75px !important;
}

.mb80 {
  margin-bottom: 80px !important;
}

.mb85 {
  margin-bottom: 85px !important;
}

.mb90 {
  margin-bottom: 90px !important;
}

.mb95 {
  margin-bottom: 95px !important;
}

.mb100 {
  margin-bottom: 100px !important;
}

/** Margin-Pixels-Bottom **/

.ml0 {
  margin-left: 0 !important;
}

.ml1 {
  margin-left: 1px !important;
}

.ml2 {
  margin-left: 2px !important;
}

.ml3 {
  margin-left: 3px !important;
}

.ml4 {
  margin-left: 4px !important;
}

.ml5 {
  margin-left: 5px !important;
}

.ml6 {
  margin-left: 6px !important;
}

.ml7 {
  margin-left: 7px !important;
}

.ml8 {
  margin-left: 8px !important;
}

.ml9 {
  margin-left: 9px !important;
}

.ml10 {
  margin-left: 10px !important;
}

.ml12 {
  margin-left: 12px !important;
}

.ml14 {
  margin-left: 14px !important;
}

.ml15 {
  margin-left: 15px !important;
}

.ml16 {
  margin-left: 16px !important;
}

.ml18 {
  margin-left: 18px !important;
}

.ml20 {
  margin-left: 20px !important;
}

.ml22 {
  margin-left: 22px !important;
}

.ml24 {
  margin-left: 24px !important;
}

.ml25 {
  margin-left: 25px !important;
}

.ml26 {
  margin-left: 26px !important;
}

.ml28 {
  margin-left: 28px !important;
}

.ml30 {
  margin-left: 30px !important;
}

.ml32 {
  margin-left: 32px !important;
}

.ml34 {
  margin-left: 34px !important;
}

.ml35 {
  margin-left: 35px !important;
}

.ml36 {
  margin-left: 36px !important;
}

.ml38 {
  margin-left: 38px !important;
}

.ml40 {
  margin-left: 40px !important;
}

.ml42 {
  margin-left: 42px !important;
}

.ml44 {
  margin-left: 44px !important;
}

.ml45 {
  margin-left: 45px !important;
}

.ml46 {
  margin-left: 46px !important;
}

.ml48 {
  margin-left: 48px !important;
}

.ml50 {
  margin-left: 50px !important;
}

.ml55 {
  margin-left: 55px !important;
}

.ml60 {
  margin-left: 60px !important;
}

.ml65 {
  margin-left: 65px !important;
}

.ml70 {
  margin-left: 70px !important;
}

.ml75 {
  margin-left: 75px !important;
}

.ml80 {
  margin-left: 80px !important;
}

.ml85 {
  margin-left: 85px !important;
}

.ml90 {
  margin-left: 90px !important;
}

.ml95 {
  margin-left: 95px !important;
}

.ml100 {
  margin-left: 100px !important;
}

/* Margin-Percent */

.m0c {
  margin: 0 !important;
}

.m1c {
  margin: 1% !important;
}

.m2c {
  margin: 2% !important;
}

.m3c {
  margin: 3% !important;
}

.m4c {
  margin: 4% !important;
}

.m5c {
  margin: 5% !important;
}

.m6c {
  margin: 6% !important;
}

.m7c {
  margin: 7% !important;
}

.m8c {
  margin: 8% !important;
}

.m9c {
  margin: 9% !important;
}

.m10c {
  margin: 10% !important;
}

.m12c {
  margin: 12% !important;
}

.m14c {
  margin: 14% !important;
}

.m15c {
  margin: 15% !important;
}

.m16c {
  margin: 16% !important;
}

.m18c {
  margin: 18% !important;
}

.m20c {
  margin: 20% !important;
}

.m22c {
  margin: 22% !important;
}

.m24c {
  margin: 24% !important;
}

.m25c {
  margin: 25% !important;
}

.m26c {
  margin: 26% !important;
}

.m28c {
  margin: 28% !important;
}

.m30c {
  margin: 30% !important;
}

.m32c {
  margin: 32% !important;
}

.m34c {
  margin: 34% !important;
}

.m35c {
  margin: 35% !important;
}

.m36c {
  margin: 36% !important;
}

.m38c {
  margin: 38% !important;
}

.m40c {
  margin: 40% !important;
}

.m42c {
  margin: 42% !important;
}

.m44c {
  margin: 44% !important;
}

.m45c {
  margin: 45% !important;
}

.m46c {
  margin: 46% !important;
}

.m48c {
  margin: 48% !important;
}

.m50c {
  margin: 50% !important;
}

.m55c {
  margin: 55% !important;
}

.m60c {
  margin: 60% !important;
}

.m65c {
  margin: 65% !important;
}

.m70c {
  margin: 70% !important;
}

.m75c {
  margin: 75% !important;
}

.m80c {
  margin: 80% !important;
}

.m85c {
  margin: 85% !important;
}

.m90c {
  margin: 90% !important;
}

.m95c {
  margin: 95% !important;
}

.m100c {
  margin: 100% !important;
}

/** Margin-Percent-Top **/

.mt0c {
  margin-top: 0 !important;
}

.mt1c {
  margin-top: 1% !important;
}

.mt2c {
  margin-top: 2% !important;
}

.mt3c {
  margin-top: 3% !important;
}

.mt4c {
  margin-top: 4% !important;
}

.mt5c {
  margin-top: 5% !important;
}

.mt6c {
  margin-top: 6% !important;
}

.mt7c {
  margin-top: 7% !important;
}

.mt8c {
  margin-top: 8% !important;
}

.mt9c {
  margin-top: 9% !important;
}

.mt10c {
  margin-top: 10% !important;
}

.mt12c {
  margin-top: 12% !important;
}

.mt14c {
  margin-top: 14% !important;
}

.mt15c {
  margin-top: 15% !important;
}

.mt16c {
  margin-top: 16% !important;
}

.mt18c {
  margin-top: 18% !important;
}

.mt20c {
  margin-top: 20% !important;
}

.mt22c {
  margin-top: 22% !important;
}

.mt24c {
  margin-top: 24% !important;
}

.mt25c {
  margin-top: 25% !important;
}

.mt26c {
  margin-top: 26% !important;
}

.mt28c {
  margin-top: 28% !important;
}

.mt30c {
  margin-top: 30% !important;
}

.mt32c {
  margin-top: 32% !important;
}

.mt34c {
  margin-top: 34% !important;
}

.mt35c {
  margin-top: 35% !important;
}

.mt36c {
  margin-top: 36% !important;
}

.mt38c {
  margin-top: 38% !important;
}

.mt40c {
  margin-top: 40% !important;
}

.mt42c {
  margin-top: 42% !important;
}

.mt44c {
  margin-top: 44% !important;
}

.mt45c {
  margin-top: 45% !important;
}

.mt46c {
  margin-top: 46% !important;
}

.mt48c {
  margin-top: 48% !important;
}

.mt50c {
  margin-top: 50% !important;
}

.mt55c {
  margin-top: 55% !important;
}

.mt60c {
  margin-top: 60% !important;
}

.mt65c {
  margin-top: 65% !important;
}

.mt70c {
  margin-top: 70% !important;
}

.mt75c {
  margin-top: 75% !important;
}

.mt80c {
  margin-top: 80% !important;
}

.mt85c {
  margin-top: 85% !important;
}

.mt90c {
  margin-top: 90% !important;
}

.mt95c {
  margin-top: 95% !important;
}

.mt100c {
  margin-top: 100% !important;
}

/** Margin-Percent-Right **/

.mr0c {
  margin-right: 0 !important;
}

.mr1c {
  margin-right: 1% !important;
}

.mr2c {
  margin-right: 2% !important;
}

.mr3c {
  margin-right: 3% !important;
}

.mr4c {
  margin-right: 4% !important;
}

.mr5c {
  margin-right: 5% !important;
}

.mr6c {
  margin-right: 6% !important;
}

.mr7c {
  margin-right: 7% !important;
}

.mr8c {
  margin-right: 8% !important;
}

.mr9c {
  margin-right: 9% !important;
}

.mr10c {
  margin-right: 10% !important;
}

.mr12c {
  margin-right: 12% !important;
}

.mr14c {
  margin-right: 14% !important;
}

.mr15c {
  margin-right: 15% !important;
}

.mr16c {
  margin-right: 16% !important;
}

.mr18c {
  margin-right: 18% !important;
}

.mr20c {
  margin-right: 20% !important;
}

.mr22c {
  margin-right: 22% !important;
}

.mr24c {
  margin-right: 24% !important;
}

.mr25c {
  margin-right: 25% !important;
}

.mr26c {
  margin-right: 26% !important;
}

.mr28c {
  margin-right: 28% !important;
}

.mr30c {
  margin-right: 30% !important;
}

.mr32c {
  margin-right: 32% !important;
}

.mr34c {
  margin-right: 34% !important;
}

.mr35c {
  margin-right: 35% !important;
}

.mr36c {
  margin-right: 36% !important;
}

.mr38c {
  margin-right: 38% !important;
}

.mr40c {
  margin-right: 40% !important;
}

.mr42c {
  margin-right: 42% !important;
}

.mr44c {
  margin-right: 44% !important;
}

.mr45c {
  margin-right: 45% !important;
}

.mr46c {
  margin-right: 46% !important;
}

.mr48c {
  margin-right: 48% !important;
}

.mr50c {
  margin-right: 50% !important;
}

.mr55c {
  margin-right: 55% !important;
}

.mr60c {
  margin-right: 60% !important;
}

.mr65c {
  margin-right: 65% !important;
}

.mr70c {
  margin-right: 70% !important;
}

.mr75c {
  margin-right: 75% !important;
}

.mr80c {
  margin-right: 80% !important;
}

.mr85c {
  margin-right: 85% !important;
}

.mr90c {
  margin-right: 90% !important;
}

.mr95c {
  margin-right: 95% !important;
}

.mr100c {
  margin-right: 100% !important;
}

/** Margin-Percent-Bottom **/

.mb0c {
  margin-bottom: 0 !important;
}

.mb1c {
  margin-bottom: 1% !important;
}

.mb2c {
  margin-bottom: 2% !important;
}

.mb3c {
  margin-bottom: 3% !important;
}

.mb4c {
  margin-bottom: 4% !important;
}

.mb5c {
  margin-bottom: 5% !important;
}

.mb6c {
  margin-bottom: 6% !important;
}

.mb7c {
  margin-bottom: 7% !important;
}

.mb8c {
  margin-bottom: 8% !important;
}

.mb9c {
  margin-bottom: 9% !important;
}

.mb10c {
  margin-bottom: 10% !important;
}

.mb12c {
  margin-bottom: 12% !important;
}

.mb14c {
  margin-bottom: 14% !important;
}

.mb15c {
  margin-bottom: 15% !important;
}

.mb16c {
  margin-bottom: 16% !important;
}

.mb18c {
  margin-bottom: 18% !important;
}

.mb20c {
  margin-bottom: 20% !important;
}

.mb22c {
  margin-bottom: 22% !important;
}

.mb24c {
  margin-bottom: 24% !important;
}

.mb25c {
  margin-bottom: 25% !important;
}

.mb26c {
  margin-bottom: 26% !important;
}

.mb28c {
  margin-bottom: 28% !important;
}

.mb30c {
  margin-bottom: 30% !important;
}

.mb32c {
  margin-bottom: 32% !important;
}

.mb34c {
  margin-bottom: 34% !important;
}

.mb35c {
  margin-bottom: 35% !important;
}

.mb36c {
  margin-bottom: 36% !important;
}

.mb38c {
  margin-bottom: 38% !important;
}

.mb40c {
  margin-bottom: 40% !important;
}

.mb42c {
  margin-bottom: 42% !important;
}

.mb44c {
  margin-bottom: 44% !important;
}

.mb45c {
  margin-bottom: 45% !important;
}

.mb46c {
  margin-bottom: 46% !important;
}

.mb48c {
  margin-bottom: 48% !important;
}

.mb50c {
  margin-bottom: 50% !important;
}

.mb55c {
  margin-bottom: 55% !important;
}

.mb60c {
  margin-bottom: 60% !important;
}

.mb65c {
  margin-bottom: 65% !important;
}

.mb70c {
  margin-bottom: 70% !important;
}

.mb75c {
  margin-bottom: 75% !important;
}

.mb80c {
  margin-bottom: 80% !important;
}

.mb85c {
  margin-bottom: 85% !important;
}

.mb90c {
  margin-bottom: 90% !important;
}

.mb95c {
  margin-bottom: 95% !important;
}

.mb100c {
  margin-bottom: 100% !important;
}

/** Margin-Percent-Bottom **/

.ml0c {
  margin-left: 0 !important;
}

.ml1c {
  margin-left: 1% !important;
}

.ml2c {
  margin-left: 2% !important;
}

.ml3c {
  margin-left: 3% !important;
}

.ml4c {
  margin-left: 4% !important;
}

.ml5c {
  margin-left: 5% !important;
}

.ml6c {
  margin-left: 6% !important;
}

.ml7c {
  margin-left: 7% !important;
}

.ml8c {
  margin-left: 8% !important;
}

.ml9c {
  margin-left: 9% !important;
}

.ml10c {
  margin-left: 10% !important;
}

.ml12c {
  margin-left: 12% !important;
}

.ml14c {
  margin-left: 14% !important;
}

.ml15c {
  margin-left: 15% !important;
}

.ml16c {
  margin-left: 16% !important;
}

.ml18c {
  margin-left: 18% !important;
}

.ml20c {
  margin-left: 20% !important;
}

.ml22c {
  margin-left: 22% !important;
}

.ml24c {
  margin-left: 24% !important;
}

.ml25c {
  margin-left: 25% !important;
}

.ml26c {
  margin-left: 26% !important;
}

.ml28c {
  margin-left: 28% !important;
}

.ml30c {
  margin-left: 30% !important;
}

.ml32c {
  margin-left: 32% !important;
}

.ml34c {
  margin-left: 34% !important;
}

.ml35c {
  margin-left: 35% !important;
}

.ml36c {
  margin-left: 36% !important;
}

.ml38c {
  margin-left: 38% !important;
}

.ml40c {
  margin-left: 40% !important;
}

.ml42c {
  margin-left: 42% !important;
}

.ml44c {
  margin-left: 44% !important;
}

.ml45c {
  margin-left: 45% !important;
}

.ml46c {
  margin-left: 46% !important;
}

.ml48c {
  margin-left: 48% !important;
}

.ml50c {
  margin-left: 50% !important;
}

.ml55c {
  margin-left: 55% !important;
}

.ml60c {
  margin-left: 60% !important;
}

.ml65c {
  margin-left: 65% !important;
}

.ml70c {
  margin-left: 70% !important;
}

.ml75c {
  margin-left: 75% !important;
}

.ml80c {
  margin-left: 80% !important;
}

.ml85c {
  margin-left: 85% !important;
}

.ml90c {
  margin-left: 90% !important;
}

.ml95c {
  margin-left: 95% !important;
}

.ml100c {
  margin-left: 100% !important;
}

.mcenter {
  margin: 0 auto;
}

/* Padding Pixels */

.p0 {
  padding: 0 !important;
}

.p1 {
  padding: 1px !important;
}

.p2 {
  padding: 2px !important;
}

.p3 {
  padding: 3px !important;
}

.p4 {
  padding: 4px !important;
}

.p5 {
  padding: 5px !important;
}

.p6 {
  padding: 6px !important;
}

.p7 {
  padding: 7px !important;
}

.p8 {
  padding: 8px !important;
}

.p9 {
  padding: 9px !important;
}

.p10 {
  padding: 10px !important;
}

.p12 {
  padding: 12px !important;
}

.p14 {
  padding: 14px !important;
}

.p15 {
  padding: 15px !important;
}

.p16 {
  padding: 16px !important;
}

.p18 {
  padding: 18px !important;
}

.p20 {
  padding: 20px !important;
}

.p22 {
  padding: 22px !important;
}

.p24 {
  padding: 24px !important;
}

.p25 {
  padding: 25px !important;
}

.p26 {
  padding: 26px !important;
}

.p28 {
  padding: 28px !important;
}

.p30 {
  padding: 30px !important;
}

.p32 {
  padding: 32px !important;
}

.p34 {
  padding: 34px !important;
}

.p35 {
  padding: 35px !important;
}

.p36 {
  padding: 36px !important;
}

.p38 {
  padding: 38px !important;
}

.p40 {
  padding: 40px !important;
}

.p42 {
  padding: 42px !important;
}

.p44 {
  padding: 44px !important;
}

.p45 {
  padding: 45px !important;
}

.p46 {
  padding: 46px !important;
}

.p48 {
  padding: 48px !important;
}

.p50 {
  padding: 50px !important;
}

.p55 {
  padding: 55px !important;
}

.p60 {
  padding: 60px !important;
}

.p65 {
  padding: 65px !important;
}

.p70 {
  padding: 70px !important;
}

.p75 {
  padding: 75px !important;
}

.p80 {
  padding: 80px !important;
}

.p85 {
  padding: 85px !important;
}

.p90 {
  padding: 90px !important;
}

.p95 {
  padding: 95px !important;
}

.p100 {
  padding: 100px !important;
}

/** Padding-Pixels-Top **/

.pt0 {
  padding-top: 0 !important;
}

.pt1 {
  padding-top: 1px !important;
}

.pt2 {
  padding-top: 2px !important;
}

.pt3 {
  padding-top: 3px !important;
}

.pt4 {
  padding-top: 4px !important;
}

.pt5 {
  padding-top: 5px !important;
}

.pt6 {
  padding-top: 6px !important;
}

.pt7 {
  padding-top: 7px !important;
}

.pt8 {
  padding-top: 8px !important;
}

.pt9 {
  padding-top: 9px !important;
}

.pt10 {
  padding-top: 10px !important;
}

.pt12 {
  padding-top: 12px !important;
}

.pt14 {
  padding-top: 14px !important;
}

.pt15 {
  padding-top: 15px !important;
}

.pt16 {
  padding-top: 16px !important;
}

.pt18 {
  padding-top: 18px !important;
}

.pt20 {
  padding-top: 20px !important;
}

.pt22 {
  padding-top: 22px !important;
}

.pt24 {
  padding-top: 24px !important;
}

.pt25 {
  padding-top: 25px !important;
}

.pt26 {
  padding-top: 26px !important;
}

.pt28 {
  padding-top: 28px !important;
}

.pt30 {
  padding-top: 30px !important;
}

.pt32 {
  padding-top: 32px !important;
}

.pt34 {
  padding-top: 34px !important;
}

.pt35 {
  padding-top: 35px !important;
}

.pt36 {
  padding-top: 36px !important;
}

.pt38 {
  padding-top: 38px !important;
}

.pt40 {
  padding-top: 40px !important;
}

.pt42 {
  padding-top: 42px !important;
}

.pt44 {
  padding-top: 44px !important;
}

.pt45 {
  padding-top: 45px !important;
}

.pt46 {
  padding-top: 46px !important;
}

.pt48 {
  padding-top: 48px !important;
}

.pt50 {
  padding-top: 50px !important;
}

.pt55 {
  padding-top: 55px !important;
}

.pt60 {
  padding-top: 60px !important;
}

.pt65 {
  padding-top: 65px !important;
}

.pt70 {
  padding-top: 70px !important;
}

.pt75 {
  padding-top: 75px !important;
}

.pt80 {
  padding-top: 80px !important;
}

.pt85 {
  padding-top: 85px !important;
}

.pt90 {
  padding-top: 90px !important;
}

.pt95 {
  padding-top: 95px !important;
}

.pt100 {
  padding-top: 100px !important;
}

/** Padding-Pixels-Right **/

.pr0 {
  padding-right: 0 !important;
}

.pr1 {
  padding-right: 1px !important;
}

.pr2 {
  padding-right: 2px !important;
}

.pr3 {
  padding-right: 3px !important;
}

.pr4 {
  padding-right: 4px !important;
}

.pr5 {
  padding-right: 5px !important;
}

.pr6 {
  padding-right: 6px !important;
}

.pr7 {
  padding-right: 7px !important;
}

.pr8 {
  padding-right: 8px !important;
}

.pr9 {
  padding-right: 9px !important;
}

.pr10 {
  padding-right: 10px !important;
}

.pr12 {
  padding-right: 12px !important;
}

.pr14 {
  padding-right: 14px !important;
}

.pr15 {
  padding-right: 15px !important;
}

.pr16 {
  padding-right: 16px !important;
}

.pr18 {
  padding-right: 18px !important;
}

.pr20 {
  padding-right: 20px !important;
}

.pr22 {
  padding-right: 22px !important;
}

.pr24 {
  padding-right: 24px !important;
}

.pr25 {
  padding-right: 25px !important;
}

.pr26 {
  padding-right: 26px !important;
}

.pr28 {
  padding-right: 28px !important;
}

.pr30 {
  padding-right: 30px !important;
}

.pr32 {
  padding-right: 32px !important;
}

.pr34 {
  padding-right: 34px !important;
}

.pr35 {
  padding-right: 35px !important;
}

.pr36 {
  padding-right: 36px !important;
}

.pr38 {
  padding-right: 38px !important;
}

.pr40 {
  padding-right: 40px !important;
}

.pr42 {
  padding-right: 42px !important;
}

.pr44 {
  padding-right: 44px !important;
}

.pr45 {
  padding-right: 45px !important;
}

.pr46 {
  padding-right: 46px !important;
}

.pr48 {
  padding-right: 48px !important;
}

.pr50 {
  padding-right: 50px !important;
}

.pr55 {
  padding-right: 55px !important;
}

.pr60 {
  padding-right: 60px !important;
}

.pr65 {
  padding-right: 65px !important;
}

.pr70 {
  padding-right: 70px !important;
}

.pr75 {
  padding-right: 75px !important;
}

.pr80 {
  padding-right: 80px !important;
}

.pr85 {
  padding-right: 85px !important;
}

.pr90 {
  padding-right: 90px !important;
}

.pr95 {
  padding-right: 95px !important;
}

.pr100 {
  padding-right: 100px !important;
}

/** Padding-Pixels-Bottom **/

.pb0 {
  padding-bottom: 0 !important;
}

.pb1 {
  padding-bottom: 1px !important;
}

.pb2 {
  padding-bottom: 2px !important;
}

.pb3 {
  padding-bottom: 3px !important;
}

.pb4 {
  padding-bottom: 4px !important;
}

.pb5 {
  padding-bottom: 5px !important;
}

.pb6 {
  padding-bottom: 6px !important;
}

.pb7 {
  padding-bottom: 7px !important;
}

.pb8 {
  padding-bottom: 8px !important;
}

.pb9 {
  padding-bottom: 9px !important;
}

.pb10 {
  padding-bottom: 10px !important;
}

.pb12 {
  padding-bottom: 12px !important;
}

.pb14 {
  padding-bottom: 14px !important;
}

.pb15 {
  padding-bottom: 15px !important;
}

.pb16 {
  padding-bottom: 16px !important;
}

.pb18 {
  padding-bottom: 18px !important;
}

.pb20 {
  padding-bottom: 20px !important;
}

.pb22 {
  padding-bottom: 22px !important;
}

.pb24 {
  padding-bottom: 24px !important;
}

.pb25 {
  padding-bottom: 25px !important;
}

.pb26 {
  padding-bottom: 26px !important;
}

.pb28 {
  padding-bottom: 28px !important;
}

.pb30 {
  padding-bottom: 30px !important;
}

.pb32 {
  padding-bottom: 32px !important;
}

.pb34 {
  padding-bottom: 34px !important;
}

.pb35 {
  padding-bottom: 35px !important;
}

.pb36 {
  padding-bottom: 36px !important;
}

.pb38 {
  padding-bottom: 38px !important;
}

.pb40 {
  padding-bottom: 40px !important;
}

.pb42 {
  padding-bottom: 42px !important;
}

.pb44 {
  padding-bottom: 44px !important;
}

.pb45 {
  padding-bottom: 45px !important;
}

.pb46 {
  padding-bottom: 46px !important;
}

.pb48 {
  padding-bottom: 48px !important;
}

.pb50 {
  padding-bottom: 50px !important;
}

.pb55 {
  padding-bottom: 55px !important;
}

.pb60 {
  padding-bottom: 60px !important;
}

.pb65 {
  padding-bottom: 65px !important;
}

.pb70 {
  padding-bottom: 70px !important;
}

.pb75 {
  padding-bottom: 75px !important;
}

.pb80 {
  padding-bottom: 80px !important;
}

.pb85 {
  padding-bottom: 85px !important;
}

.pb90 {
  padding-bottom: 90px !important;
}

.pb95 {
  padding-bottom: 95px !important;
}

.pb100 {
  padding-bottom: 100px !important;
}

/** Padding-Pixels-Bottom **/

.pl0 {
  padding-left: 0 !important;
}

.pl1 {
  padding-left: 1px !important;
}

.pl2 {
  padding-left: 2px !important;
}

.pl3 {
  padding-left: 3px !important;
}

.pl4 {
  padding-left: 4px !important;
}

.pl5 {
  padding-left: 5px !important;
}

.pl6 {
  padding-left: 6px !important;
}

.pl7 {
  padding-left: 7px !important;
}

.pl8 {
  padding-left: 8px !important;
}

.pl9 {
  padding-left: 9px !important;
}

.pl10 {
  padding-left: 10px !important;
}

.pl12 {
  padding-left: 12px !important;
}

.pl14 {
  padding-left: 14px !important;
}

.pl15 {
  padding-left: 15px !important;
}

.pl16 {
  padding-left: 16px !important;
}

.pl18 {
  padding-left: 18px !important;
}

.pl20 {
  padding-left: 20px !important;
}

.pl22 {
  padding-left: 22px !important;
}

.pl24 {
  padding-left: 24px !important;
}

.pl25 {
  padding-left: 25px !important;
}

.pl26 {
  padding-left: 26px !important;
}

.pl28 {
  padding-left: 28px !important;
}

.pl30 {
  padding-left: 30px !important;
}

.pl32 {
  padding-left: 32px !important;
}

.pl34 {
  padding-left: 34px !important;
}

.pl35 {
  padding-left: 35px !important;
}

.pl36 {
  padding-left: 36px !important;
}

.pl38 {
  padding-left: 38px !important;
}

.pl40 {
  padding-left: 40px !important;
}

.pl42 {
  padding-left: 42px !important;
}

.pl44 {
  padding-left: 44px !important;
}

.pl45 {
  padding-left: 45px !important;
}

.pl46 {
  padding-left: 46px !important;
}

.pl48 {
  padding-left: 48px !important;
}

.pl50 {
  padding-left: 50px !important;
}

.pl55 {
  padding-left: 55px !important;
}

.pl60 {
  padding-left: 60px !important;
}

.pl65 {
  padding-left: 65px !important;
}

.pl70 {
  padding-left: 70px !important;
}

.pl75 {
  padding-left: 75px !important;
}

.pl80 {
  padding-left: 80px !important;
}

.pl85 {
  padding-left: 85px !important;
}

.pl90 {
  padding-left: 90px !important;
}

.pl95 {
  padding-left: 95px !important;
}

.pl100 {
  padding-left: 100px !important;
}

/* Padding-Percent */

.p0c {
  padding: 0 !important;
}

.p1c {
  padding: 1% !important;
}

.p2c {
  padding: 2% !important;
}

.p3c {
  padding: 3% !important;
}

.p4c {
  padding: 4% !important;
}

.p5c {
  padding: 5% !important;
}

.p6c {
  padding: 6% !important;
}

.p7c {
  padding: 7% !important;
}

.p8c {
  padding: 8% !important;
}

.p9c {
  padding: 9% !important;
}

.p10c {
  padding: 10% !important;
}

.p12c {
  padding: 12% !important;
}

.p14c {
  padding: 14% !important;
}

.p15c {
  padding: 15% !important;
}

.p16c {
  padding: 16% !important;
}

.p18c {
  padding: 18% !important;
}

.p20c {
  padding: 20% !important;
}

.p22c {
  padding: 22% !important;
}

.p24c {
  padding: 24% !important;
}

.p25c {
  padding: 25% !important;
}

.p26c {
  padding: 26% !important;
}

.p28c {
  padding: 28% !important;
}

.p30c {
  padding: 30% !important;
}

.p32c {
  padding: 32% !important;
}

.p34c {
  padding: 34% !important;
}

.p35c {
  padding: 35% !important;
}

.p36c {
  padding: 36% !important;
}

.p38c {
  padding: 38% !important;
}

.p40c {
  padding: 40% !important;
}

.p42c {
  padding: 42% !important;
}

.p44c {
  padding: 44% !important;
}

.p45c {
  padding: 45% !important;
}

.p46c {
  padding: 46% !important;
}

.p48c {
  padding: 48% !important;
}

.p50c {
  padding: 50% !important;
}

.p55c {
  padding: 55% !important;
}

.p60c {
  padding: 60% !important;
}

.p65c {
  padding: 65% !important;
}

.p70c {
  padding: 70% !important;
}

.p75c {
  padding: 75% !important;
}

.p80c {
  padding: 80% !important;
}

.p85c {
  padding: 85% !important;
}

.p90c {
  padding: 90% !important;
}

.p95c {
  padding: 95% !important;
}

.p100c {
  padding: 100% !important;
}

/** Padding-Percent-Top **/

.pt0c {
  padding-top: 0 !important;
}

.pt1c {
  padding-top: 1% !important;
}

.pt2c {
  padding-top: 2% !important;
}

.pt3c {
  padding-top: 3% !important;
}

.pt4c {
  padding-top: 4% !important;
}

.pt5c {
  padding-top: 5% !important;
}

.pt6c {
  padding-top: 6% !important;
}

.pt7c {
  padding-top: 7% !important;
}

.pt8c {
  padding-top: 8% !important;
}

.pt9c {
  padding-top: 9% !important;
}

.pt10c {
  padding-top: 10% !important;
}

.pt12c {
  padding-top: 12% !important;
}

.pt14c {
  padding-top: 14% !important;
}

.pt15c {
  padding-top: 15% !important;
}

.pt16c {
  padding-top: 16% !important;
}

.pt18c {
  padding-top: 18% !important;
}

.pt20c {
  padding-top: 20% !important;
}

.pt22c {
  padding-top: 22% !important;
}

.pt24c {
  padding-top: 24% !important;
}

.pt25c {
  padding-top: 25% !important;
}

.pt26c {
  padding-top: 26% !important;
}

.pt28c {
  padding-top: 28% !important;
}

.pt30c {
  padding-top: 30% !important;
}

.pt32c {
  padding-top: 32% !important;
}

.pt34c {
  padding-top: 34% !important;
}

.pt35c {
  padding-top: 35% !important;
}

.pt36c {
  padding-top: 36% !important;
}

.pt38c {
  padding-top: 38% !important;
}

.pt40c {
  padding-top: 40% !important;
}

.pt42c {
  padding-top: 42% !important;
}

.pt44c {
  padding-top: 44% !important;
}

.pt45c {
  padding-top: 45% !important;
}

.pt46c {
  padding-top: 46% !important;
}

.pt48c {
  padding-top: 48% !important;
}

.pt50c {
  padding-top: 50% !important;
}

.pt55c {
  padding-top: 55% !important;
}

.pt60c {
  padding-top: 60% !important;
}

.pt65c {
  padding-top: 65% !important;
}

.pt70c {
  padding-top: 70% !important;
}

.pt75c {
  padding-top: 75% !important;
}

.pt80c {
  padding-top: 80% !important;
}

.pt85c {
  padding-top: 85% !important;
}

.pt90c {
  padding-top: 90% !important;
}

.pt95c {
  padding-top: 95% !important;
}

.pt100c {
  padding-top: 100% !important;
}

/** Padding-Percent-Right **/

.pr0c {
  padding-right: 0 !important;
}

.pr1c {
  padding-right: 1% !important;
}

.pr2c {
  padding-right: 2% !important;
}

.pr3c {
  padding-right: 3% !important;
}

.pr4c {
  padding-right: 4% !important;
}

.pr5c {
  padding-right: 5% !important;
}

.pr6c {
  padding-right: 6% !important;
}

.pr7c {
  padding-right: 7% !important;
}

.pr8c {
  padding-right: 8% !important;
}

.pr9c {
  padding-right: 9% !important;
}

.pr10c {
  padding-right: 10% !important;
}

.pr12c {
  padding-right: 12% !important;
}

.pr14c {
  padding-right: 14% !important;
}

.pr15c {
  padding-right: 15% !important;
}

.pr16c {
  padding-right: 16% !important;
}

.pr18c {
  padding-right: 18% !important;
}

.pr20c {
  padding-right: 20% !important;
}

.pr22c {
  padding-right: 22% !important;
}

.pr24c {
  padding-right: 24% !important;
}

.pr25c {
  padding-right: 25% !important;
}

.pr26c {
  padding-right: 26% !important;
}

.pr28c {
  padding-right: 28% !important;
}

.pr30c {
  padding-right: 30% !important;
}

.pr32c {
  padding-right: 32% !important;
}

.pr34c {
  padding-right: 34% !important;
}

.pr35c {
  padding-right: 35% !important;
}

.pr36c {
  padding-right: 36% !important;
}

.pr38c {
  padding-right: 38% !important;
}

.pr40c {
  padding-right: 40% !important;
}

.pr42c {
  padding-right: 42% !important;
}

.pr44c {
  padding-right: 44% !important;
}

.pr45c {
  padding-right: 45% !important;
}

.pr46c {
  padding-right: 46% !important;
}

.pr48c {
  padding-right: 48% !important;
}

.pr50c {
  padding-right: 50% !important;
}

.pr55c {
  padding-right: 55% !important;
}

.pr60c {
  padding-right: 60% !important;
}

.pr65c {
  padding-right: 65% !important;
}

.pr70c {
  padding-right: 70% !important;
}

.pr75c {
  padding-right: 75% !important;
}

.pr80c {
  padding-right: 80% !important;
}

.pr85c {
  padding-right: 85% !important;
}

.pr90c {
  padding-right: 90% !important;
}

.pr95c {
  padding-right: 95% !important;
}

.pr100c {
  padding-right: 100% !important;
}

/** Padding-Percent-Bottom **/

.pb0c {
  padding-bottom: 0 !important;
}

.pb1c {
  padding-bottom: 1% !important;
}

.pb2c {
  padding-bottom: 2% !important;
}

.pb3c {
  padding-bottom: 3% !important;
}

.pb4c {
  padding-bottom: 4% !important;
}

.pb5c {
  padding-bottom: 5% !important;
}

.pb6c {
  padding-bottom: 6% !important;
}

.pb7c {
  padding-bottom: 7% !important;
}

.pb8c {
  padding-bottom: 8% !important;
}

.pb9c {
  padding-bottom: 9% !important;
}

.pb10c {
  padding-bottom: 10% !important;
}

.pb12c {
  padding-bottom: 12% !important;
}

.pb14c {
  padding-bottom: 14% !important;
}

.pb15c {
  padding-bottom: 15% !important;
}

.pb16c {
  padding-bottom: 16% !important;
}

.pb18c {
  padding-bottom: 18% !important;
}

.pb20c {
  padding-bottom: 20% !important;
}

.pb22c {
  padding-bottom: 22% !important;
}

.pb24c {
  padding-bottom: 24% !important;
}

.pb25c {
  padding-bottom: 25% !important;
}

.pb26c {
  padding-bottom: 26% !important;
}

.pb28c {
  padding-bottom: 28% !important;
}

.pb30c {
  padding-bottom: 30% !important;
}

.pb32c {
  padding-bottom: 32% !important;
}

.pb34c {
  padding-bottom: 34% !important;
}

.pb35c {
  padding-bottom: 35% !important;
}

.pb36c {
  padding-bottom: 36% !important;
}

.pb38c {
  padding-bottom: 38% !important;
}

.pb40c {
  padding-bottom: 40% !important;
}

.pb42c {
  padding-bottom: 42% !important;
}

.pb44c {
  padding-bottom: 44% !important;
}

.pb45c {
  padding-bottom: 45% !important;
}

.pb46c {
  padding-bottom: 46% !important;
}

.pb48c {
  padding-bottom: 48% !important;
}

.pb50c {
  padding-bottom: 50% !important;
}

.pb55c {
  padding-bottom: 55% !important;
}

.pb60c {
  padding-bottom: 60% !important;
}

.pb65c {
  padding-bottom: 65% !important;
}

.pb70c {
  padding-bottom: 70% !important;
}

.pb75c {
  padding-bottom: 75% !important;
}

.pb80c {
  padding-bottom: 80% !important;
}

.pb85c {
  padding-bottom: 85% !important;
}

.pb90c {
  padding-bottom: 90% !important;
}

.pb95c {
  padding-bottom: 95% !important;
}

.pb100c {
  padding-bottom: 100% !important;
}

/** Padding-Percent-Bottom **/

.pl0c {
  padding-left: 0 !important;
}

.pl1c {
  padding-left: 1% !important;
}

.pl2c {
  padding-left: 2% !important;
}

.pl3c {
  padding-left: 3% !important;
}

.pl4c {
  padding-left: 4% !important;
}

.pl5c {
  padding-left: 5% !important;
}

.pl6c {
  padding-left: 6% !important;
}

.pl7c {
  padding-left: 7% !important;
}

.pl8c {
  padding-left: 8% !important;
}

.pl9c {
  padding-left: 9% !important;
}

.pl10c {
  padding-left: 10% !important;
}

.pl12c {
  padding-left: 12% !important;
}

.pl14c {
  padding-left: 14% !important;
}

.pl15c {
  padding-left: 15% !important;
}

.pl16c {
  padding-left: 16% !important;
}

.pl18c {
  padding-left: 18% !important;
}

.pl20c {
  padding-left: 20% !important;
}

.pl22c {
  padding-left: 22% !important;
}

.pl24c {
  padding-left: 24% !important;
}

.pl25c {
  padding-left: 25% !important;
}

.pl26c {
  padding-left: 26% !important;
}

.pl28c {
  padding-left: 28% !important;
}

.pl30c {
  padding-left: 30% !important;
}

.pl32c {
  padding-left: 32% !important;
}

.pl34c {
  padding-left: 34% !important;
}

.pl35c {
  padding-left: 35% !important;
}

.pl36c {
  padding-left: 36% !important;
}

.pl38c {
  padding-left: 38% !important;
}

.pl40c {
  padding-left: 40% !important;
}

.pl42c {
  padding-left: 42% !important;
}

.pl44c {
  padding-left: 44% !important;
}

.pl45c {
  padding-left: 45% !important;
}

.pl46c {
  padding-left: 46% !important;
}

.pl48c {
  padding-left: 48% !important;
}

.pl50c {
  padding-left: 50% !important;
}

.pl55c {
  padding-left: 55% !important;
}

.pl60c {
  padding-left: 60% !important;
}

.pl65c {
  padding-left: 65% !important;
}

.pl70c {
  padding-left: 70% !important;
}

.pl75c {
  padding-left: 75% !important;
}

.pl80c {
  padding-left: 80% !important;
}

.pl85c {
  padding-left: 85% !important;
}

.pl90c {
  padding-left: 90% !important;
}

.pl95c {
  padding-left: 95% !important;
}

.pl100c {
  padding-left: 100% !important;
}

/* Height */
/** Height-Pixels **/

.h0 {
  height: 0px !important;
}

.h1 {
  height: 1px !important;
}

.h2 {
  height: 2px !important;
}

.h3 {
  height: 3px !important;
}

.h4 {
  height: 4px !important;
}

.h5 {
  height: 5px !important;
}

.h7 {
  height: 6px !important;
  height: 7px !important;
}

.h8 {
  height: 8px !important;
}

.h9 {
  height: 9px !important;
}

.h10 {
  height: 10px !important;
}

.h11 {
  height: 11px !important;
}

.h12 {
  height: 12px !important;
}

.h13 {
  height: 13px !important;
}

.h14 {
  height: 14px !important;
}

.h15 {
  height: 15px !important;
}

.h16 {
  height: 16px !important;
}

.h17 {
  height: 17px !important;
}

.h18 {
  height: 18px !important;
}

.h19 {
  height: 19px !important;
}

.h20 {
  height: 20px !important;
}

.h22 {
  height: 22px !important;
}

.h24 {
  height: 24px !important;
}

.h25 {
  height: 25px !important;
}

.h26 {
  height: 26px !important;
}

.h28 {
  height: 28px !important;
}

.h30 {
  height: 30px !important;
}

.h32 {
  height: 32px !important;
}

.h34 {
  height: 34px !important;
}

.h35 {
  height: 35px !important;
}

.h36 {
  height: 36px !important;
}

.h38 {
  height: 38px !important;
}

.h40 {
  height: 40px !important;
}

.h42 {
  height: 42px !important;
}

.h44 {
  height: 44px !important;
}

.h45 {
  height: 45px !important;
}

.h46 {
  height: 46px !important;
}

.h48 {
  height: 48px !important;
}

.h50 {
  height: 50px !important;
}

.h55 {
  height: 55px !important;
}

.h60 {
  height: 60px !important;
}

.h65 {
  height: 65px !important;
}

.h70 {
  height: 70px !important;
}

.h75 {
  height: 75px !important;
}

.h80 {
  height: 80px !important;
}

.h85 {
  height: 85px !important;
}

.h90 {
  height: 90px !important;
}

.h95 {
  height: 95px !important;
}

.h100 {
  height: 100px !important;
}

.h105 {
  height: 105px !important;
}

.h110 {
  height: 110px !important;
}

.h115 {
  height: 115px !important;
}

.h120 {
  height: 120px !important;
}

.h125 {
  height: 125px !important;
}

.h130 {
  height: 130px !important;
}

.h135 {
  height: 135px !important;
}

.h140 {
  height: 140px !important;
}

.h145 {
  height: 145px !important;
}

.h150 {
  height: 150px !important;
}

.h155 {
  height: 155px !important;
}

.h160 {
  height: 160px !important;
}

.h165 {
  height: 165px !important;
}

.h170 {
  height: 170px !important;
}

.h175 {
  height: 175px !important;
}

.h180 {
  height: 180px !important;
}

.h185 {
  height: 185px !important;
}

.h190 {
  height: 190px !important;
}

.h195 {
  height: 195px !important;
}

.h200 {
  height: 200px !important;
}

.h210 {
  height: 210px !important;
}

.h220 {
  height: 220px !important;
}

.h230 {
  height: 230px !important;
}

.h240 {
  height: 240px !important;
}

.h250 {
  height: 250px !important;
}

.h260 {
  height: 260px !important;
}

.h270 {
  height: 270px !important;
}

.h280 {
  height: 280px !important;
}

.h290 {
  height: 290px !important;
}

.h300 {
  height: 300px !important;
}

.h310 {
  height: 310px !important;
}

.h320 {
  height: 320px !important;
}

.h340 {
  height: 340px !important;
}

.h350 {
  height: 350px !important;
}

/** Height-Percent **/

.h0c {
  height: 0% !important;
}

.h1c {
  height: 1% !important;
}

.h2c {
  height: 2% !important;
}

.h3c {
  height: 3% !important;
}

.h4c {
  height: 4% !important;
}

.h5c {
  height: 5% !important;
}

.h10c {
  height: 10% !important;
}

.h15c {
  height: 15% !important;
}

.h20c {
  height: 20% !important;
}

.h25c {
  height: 25% !important;
}

.h30c {
  height: 30% !important;
}

.h35c {
  height: 35% !important;
}

.h40c {
  height: 40% !important;
}

.h45c {
  height: 45% !important;
}

.h50c {
  height: 50% !important;
}

.h55c {
  height: 55% !important;
}

.h60c {
  height: 60% !important;
}

.h65c {
  height: 65% !important;
}

.h70c {
  height: 70% !important;
}

.h75c {
  height: 75% !important;
}

.h80c {
  height: 80% !important;
}

.h85c {
  height: 85% !important;
}

.h90c {
  height: 90% !important;
}

.h95c {
  height: 95% !important;
}

.h100c {
  height: 100% !important;
}

.h105c {
  height: 105% !important;
}

.h110c {
  height: 110% !important;
}

.h115c {
  height: 115% !important;
}

.h120c {
  height: 120% !important;
}

.h125c {
  height: 125% !important;
}

.h130c {
  height: 130% !important;
}

.h135c {
  height: 135% !important;
}

.h140c {
  height: 140% !important;
}

.h145c {
  height: 145% !important;
}

.h150c {
  height: 150% !important;
}

/* Width */
/** Width-Pixels **/

.w0 {
  width: 0px !important;
}

.w1 {
  width: 1px !important;
}

.w2 {
  width: 2px !important;
}

.w3 {
  width: 3px !important;
}

.w4 {
  width: 4px !important;
}

.w5 {
  width: 5px !important;
}

.w7 {
  width: 6px !important;
  width: 7px !important;
}

.w8 {
  width: 8px !important;
}

.w9 {
  width: 9px !important;
}

.w10 {
  width: 10px !important;
}

.w11 {
  width: 11px !important;
}

.w12 {
  width: 12px !important;
}

.w13 {
  width: 13px !important;
}

.w14 {
  width: 14px !important;
}

.w15 {
  width: 15px !important;
}

.w16 {
  width: 16px !important;
}

.w17 {
  width: 17px !important;
}

.w18 {
  width: 18px !important;
}

.w19 {
  width: 19px !important;
}

.w20 {
  width: 20px !important;
}

.w22 {
  width: 22px !important;
}

.w24 {
  width: 24px !important;
}

.w25 {
  width: 25px !important;
}

.w26 {
  width: 26px !important;
}

.w28 {
  width: 28px !important;
}

.w30 {
  width: 30px !important;
}

.w32 {
  width: 32px !important;
}

.w34 {
  width: 34px !important;
}

.w35 {
  width: 35px !important;
}

.w36 {
  width: 36px !important;
}

.w38 {
  width: 38px !important;
}

.w40 {
  width: 40px !important;
}

.w42 {
  width: 42px !important;
}

.w44 {
  width: 44px !important;
}

.w45 {
  width: 45px !important;
}

.w46 {
  width: 46px !important;
}

.w48 {
  width: 48px !important;
}

.w50 {
  width: 50px !important;
}

.w55 {
  width: 55px !important;
}

.w60 {
  width: 60px !important;
}

.w65 {
  width: 65px !important;
}

.w70 {
  width: 70px !important;
}

.w75 {
  width: 75px !important;
}

.w80 {
  width: 80px !important;
}

.w85 {
  width: 85px !important;
}

.w90 {
  width: 90px !important;
}

.w95 {
  width: 95px !important;
}

.w100 {
  width: 100px !important;
}

.w105 {
  width: 105px !important;
}

.w110 {
  width: 110px !important;
}

.w115 {
  width: 115px !important;
}

.w120 {
  width: 120px !important;
}

.w125 {
  width: 125px !important;
}

.w130 {
  width: 130px !important;
}

.w135 {
  width: 135px !important;
}

.w140 {
  width: 140px !important;
}

.w145 {
  width: 145px !important;
}

.w150 {
  width: 150px !important;
}

.w155 {
  width: 155px !important;
}

.w160 {
  width: 160px !important;
}

.w165 {
  width: 165px !important;
}

.w170 {
  width: 170px !important;
}

.w175 {
  width: 175px !important;
}

.w180 {
  width: 180px !important;
}

.w185 {
  width: 185px !important;
}

.w190 {
  width: 190px !important;
}

.w195 {
  width: 195px !important;
}

.w200 {
  width: 200px !important;
}

.w210 {
  width: 210px !important;
}

.w220 {
  width: 220px !important;
}

.w230 {
  width: 230px !important;
}

.w240 {
  width: 240px !important;
}

.w250 {
  width: 250px !important;
}

.w260 {
  width: 260px !important;
}

.w270 {
  width: 270px !important;
}

.w280 {
  width: 280px !important;
}

.w290 {
  width: 290px !important;
}

.w300 {
  width: 300px !important;
}

.w310 {
  width: 310px !important;
}

.w320 {
  width: 320px !important;
}

.w340 {
  width: 340px !important;
}

.w350 {
  width: 350px !important;
}

/** Width-Percent **/

.w0c {
  width: 0% !important;
}

.w1c {
  width: 1% !important;
}

.w2c {
  width: 2% !important;
}

.w3c {
  width: 3% !important;
}

.w4c {
  width: 4% !important;
}

.w5c {
  width: 5% !important;
}

.w10c {
  width: 10% !important;
}

.w15c {
  width: 15% !important;
}

.w20c {
  width: 20% !important;
}

.w25c {
  width: 25% !important;
}

.w30c {
  width: 30% !important;
}

.w35c {
  width: 35% !important;
}

.w40c {
  width: 40% !important;
}

.w45c {
  width: 45% !important;
}

.w50c {
  width: 50% !important;
}

.w55c {
  width: 55% !important;
}

.w60c {
  width: 60% !important;
}

.w65c {
  width: 65% !important;
}

.w70c {
  width: 70% !important;
}

.w75c {
  width: 75% !important;
}

.w80c {
  width: 80% !important;
}

.w85c {
  width: 85% !important;
}

.w90c {
  width: 90% !important;
}

.w95c {
  width: 95% !important;
}

.w100c {
  width: 100% !important;
}

.w105c {
  width: 105% !important;
}

.w110c {
  width: 110% !important;
}

.w115c {
  width: 115% !important;
}

.w120c {
  width: 120% !important;
}

.w125c {
  width: 125% !important;
}

.w130c {
  width: 130% !important;
}

.w135c {
  width: 135% !important;
}

.w140c {
  width: 140% !important;
}

.w145c {
  width: 145% !important;
}

.w150c {
  width: 150% !important;
}

/* Line Height */

.lh2 {
  line-height: 2px !important;
}

.lh4 {
  line-height: 4px !important;
}

.lh5 {
  line-height: 5px !important;
}

.lh6 {
  line-height: 6px !important;
}

.lh8 {
  line-height: 8px !important;
}

.lh10 {
  line-height: 10px !important;
}

.lh12 {
  line-height: 12px !important;
}

.lh14 {
  line-height: 14px !important;
}

.lh15 {
  line-height: 15px !important;
}

.lh16 {
  line-height: 16px !important;
}

.lh18 {
  line-height: 18px !important;
}

.lh20 {
  line-height: 20px !important;
}

.lh22 {
  line-height: 22px !important;
}

.lh24 {
  line-height: 24px !important;
}

.lh25 {
  line-height: 25px !important;
}

.lh26 {
  line-height: 26px !important;
}

.lh28 {
  line-height: 28px !important;
}

.lh30 {
  line-height: 30px !important;
}

.lh32 {
  line-height: 32px !important;
}

.lh34 {
  line-height: 34px !important;
}

.lh35 {
  line-height: 35px !important;
}

.lh36 {
  line-height: 36px !important;
}

.lh38 {
  line-height: 38px !important;
}

.lh40 {
  line-height: 40px !important;
}

.lh42 {
  line-height: 42px !important;
}

.lh44 {
  line-height: 44px !important;
}

.lh45 {
  line-height: 45px !important;
}

.lh46 {
  line-height: 46px !important;
}

.lh48 {
  line-height: 48px !important;
}

.lh50 {
  line-height: 50px !important;
}

/* Horizontal Rule */

hr {
  display: block;
  border: none;
  &[class*="vhr"] {
    display: inline-block;
  }
  &.hr5 {
    margin: 2.5px 0 !important;
  }
  &.hr10 {
    margin: 5px 0 !important;
  }
  &.hr15 {
    margin: 7.5px 0 !important;
  }
  &.hr20 {
    margin: 10px 0 !important;
  }
  &.hr25 {
    margin: 12.5px 0 !important;
  }
  &.hr30 {
    margin: 15px 0 !important;
  }
  &.hr35 {
    margin: 17.5px 0 !important;
  }
  &.hr40 {
    margin: 20px 0 !important;
  }
  &.hr45 {
    margin: 22.5px 0 !important;
  }
  &.hr50 {
    margin: 25px 0 !important;
  }
  &[class*="vhr"].line {
    line-height: 100% !important;
    &::after {
      content: '';
      // border-right: solid 1px #ccc;
    }
  }
  &.vhr5 {
    margin: 0 2.5px !important;
  }
  &.vhr10 {
    margin: 0 5px !important;
  }
  &.vhr15 {
    margin: 0 7.5px !important;
  }
  &.vhr20 {
    margin: 0 10px !important;
  }
  &.vhr25 {
    margin: 12.5px 0 !important;
  }
  &.vhr30 {
    margin: 15px 0 !important;
  }
  &.vhr35 {
    margin: 17.5px 0 !important;
  }
  &.vhr40 {
    margin: 20px 0 !important;
  }
  &.vhr45 {
    margin: 22.5px 0 !important;
  }
  &.vhr50 {
    margin: 25px 0 !important;
  }
}

.bb {
  border-bottom: solid 1px #e4e4e4;
}
.bold {
  font-weight: bold;
}

