#tokens {
  margin-top: 0 !important;
  background-color: rgba(8, 49, 92, .4) !important;
  padding: 100px 50px 100px 50px !important;
  // text-align: center;
	color: #fff;
  .section-title {
    text-align: center;
  }
  .token-btn {
    text-align: center;
  }
}

.table > :not(caption) > * > * {
  border-color: rgba(8, 49, 92, .4) !important;
}