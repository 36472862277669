@import '../../../scss/style';
@import './About/About.scss';
@import './Contact/Contact.scss';
@import './Ico/Ico.scss';

section.section
{
	backdrop-filter: blur(7px);
	margin-top: 0px !important;
	padding: 100px 50px 100px 50px !important;
	&.top {
		padding-top: 20px !important;
		// border-top: solid 1px #fff;
	}
}


  // 41
h2, h3, h4 {
    font-family: 'Raleway', sans-serif;
}
h2 {
    text-transform: uppercase;
    margin: 0 0 20px 0;
    font-weight: 800;
    font-size: 36px;
    // color: #333;
}
h3 {
    font-size: 20px;
    font-weight: 600;
    // color: #333;
}
h4 {
    font-size: 18px;
    // color: #333;
    font-weight: 600;
}
h5 {
    text-transform: uppercase;
    font-weight: 700;
    line-height: 20px;
}
p {
    font-size: 1rem;;
}
p.intro {
    margin: 12px 0 0;
    line-height: 24px;
}
a {
    color: #608dfd;
    font-weight: 400;
}
a:hover, a:focus {
    text-decoration: none;
    color: #608dfd;
}
ul, ol {
    list-style: none;
}
ul, ol {
    padding: 0;
    webkit-padding: 0;
    moz-padding: 0;
}
hr {
    width: 100%;
    border-top: 1px solid rgba(255,255,255,0.15);
    position: relative;
    margin: auto;
    margin-bottom: 20px;
    border: 0;
}

.section-title {
	margin-bottom: 70px;
}
.section-title h2 {
	position: relative;
	margin-top: 10px;
	margin-bottom: 15px;
	padding-bottom: 15px;
}
.section-title h2::after {
	position: absolute;
	content: "";
	background: linear-gradient(to right, $blue_default 0%, $blue_default_2 100%);
	height: 4px;
	width: 60px;
	bottom: 0;
	margin-left: -30px;
	left: 50%;
}
.section-title p {
	font-size: 18px;
}
.btn-custom {
	font-family: 'Raleway', sans-serif;
	text-transform: uppercase;
	color: #fff;
	// background-color: #5ca9fb;
	// background-image: linear-gradient(to right, #5ca9fb 0%, #6372ff 100%);
	padding: 7px 17px;
	letter-spacing: 1px;
	margin: 0;
	font-size: 12px;
	font-weight: 700 !important;
	border-radius: 25px;
	transition: all 0.5s linear;
	border: 0;
}
.btn-custom:hover, .btn-custom:focus, .btn-custom.focus, .btn-custom:active, .btn-custom.active {
	color: #fff;
	background-image: none;
	background-color: #6372ff;
}
.btn:active, .btn.active {
	background-image: none;
	outline: 0;
	-webkit-box-shadow: none;
	box-shadow: none;
}
a:focus, .btn:focus, .btn:active:focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn.active.focus {
	outline: none;
	outline-offset: none;
}
/* Header Section */
.background {
	background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%)
}
.map {
	background: url(../../../assets/worldmap.png) center center no-repeat;
	background-size: 100%;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	background-size: cover;
	-o-background-size: cover;
}
.intro {
	display: table;
	width: 100%;
	padding: 0;
	
	/* opacity: 0.3; */
	/* background: #c9e08e; */
	/* -webkit-background-size: cover;
	-moz-background-size: cover;
	background-size: cover;
	-o-background-size: cover; */
	
}
.intro .overlay {
	
	
	
	/* margin-top: -910px; */
}
.intro h1 {
	font-family: 'Raleway', sans-serif;
	color: #fff;
	font-size: 82px;
	font-weight: 700;
	text-transform: uppercase;
	margin-top: 0;
	margin-bottom: 10px;
}
.intro h1 span {
	font-weight: 800;
	color: #5ca9fb;
}
.intro p {
	color: #fff;
	font-size: 22px;
	font-weight: 300;
	line-height: 30px;
	margin: 0 auto;
	margin-bottom: 60px;
}
header .intro-text {
	padding-top: 350px;
	padding-bottom: 200px;
	text-align: center;
}
/* Roadmap Section */

/* About Section */

/* Info Section */
#info {
	background: linear-gradient(to right, #1bb835 0%, #1b8d17 100%);
	color: #fff;
	padding-top: 5%;
}
/* tokenomics Section */
#tokenomics {
	background: linear-gradient(to right, #1bb835 0%, #1b8d17 100%);
	color: #fff;
	padding-top: 5%;
}
#tokenomics .service-desc {
	margin: 10px 10px 20px;
}
#tokenomics h2 {
	color: #fff;
}
#tokenomics .section-title h2::after {
	position: absolute;
	content: "";
	background: rgba(255,255,255,.3);
	height: 4px;
	width: 60px;
	bottom: 0;
	margin-left: -30px;
	left: 50%;
}
#tokenomics i.fa, #tokenomics i.fas {
	font-size: 42px;
	width: 120px;
	height: 120px;
	padding: 40px 0;
	background: linear-gradient(to right, #000000 0%, #242525 100%);
	border-radius: 50%;
	color: #fff;
	box-shadow: 10px 10px 10px rgba(0,0,0,.05);
}
#tokenomics h3 {
	font-weight: 500;
	padding: 5px 0;
	color: #fff;
}
#tokenomics p {
	color: rgba(255,255,255,.75);
}
#tokenomics .service-desc {
	margin-bottom: 40px;
}
/* Portfolio Section */
#portfolio {
	padding: 100px 0;
}
.portfolio-item {
	margin: 1px -15px 0 -14px;
	padding: 0;
}
.portfolio-item .hover-bg {
	overflow: hidden;
	position: relative;
	margin: 0;
}
.hover-bg .hover-text {
	position: absolute;
	text-align: center;
	margin: 0 auto;
	color: #fff;
	background: linear-gradient(to right, rgba(99,114,255,0.8) 0%, rgba(92,169,251,0.8) 100%);
	padding: 30% 0 0 0;
	height: 100%;
	width: 100%;
	opacity: 0;
	transition: all 0.5s;
}
.hover-bg .hover-text>h4 {
	opacity: 0;
	color: #fff;
	-webkit-transform: translateY(100%);
	transform: translateY(100%);
	transition: all 0.3s;
	font-size: 18px;
	letter-spacing: 1px;
	font-weight: 500;
	text-transform: uppercase;
}
.hover-bg:hover .hover-text>h4 {
	opacity: 1;
	-webkit-backface-visibility: hidden;
	-webkit-transform: translateY(0);
	transform: translateY(0);
}
.hover-bg:hover .hover-text {
	opacity: 1;
}
/* Testimonials Section */
#testimonials {
	padding: 100px 0;
	background: #f6f6f6;
}
#testimonials i {
	color: #e6e6e6;
	font-size: 32px;
	margin-bottom: 20px;
}
.testimonial {
	position: relative;
	padding: 20px;
}
.testimonial-image {
	float: left;
	margin-right: 15px;
}
.testimonial-image, .testimonial-image img {
	display: block;
	width: 64px;
	height: 64px;
	border-radius: 50%;
}
.testimonial-content {
	position: relative;
	overflow: hidden;
}
.testimonial-content p {
	margin-bottom: 0;
	font-size: 14px;
	font-style: italic;
}
.testimonial-meta {
	margin-top: 10px;
	font-size: 15px;
	font-weight: 600;
	color: #666;
}
/* Team Section */
#team {
	padding: 100px 0;
}
#team h4 {
	margin: 5px 0;
}
#team .team-img {
	width: 240px;
}
#team .thumbnail {
	background: transparent;
	border: 0;
}
#team .thumbnail .caption {
	padding: 10px 0 0 0;
	color: #888;
}
.welcome {
	background:#08315c;
  margin-top: -50px;
	padding: 100px 50px 200px 50px !important;
  h1 {
    font-family: 'Montserrat', sans-serif;
    margin-bottom: 50px;
    font-weight: 800;
    letter-spacing: .15rem;
    z-index: 2;
    position: relative;
    }
    .ContactLogo {
      text-align: center;
      div {
        width: 150px;
        height: 150px;
        position: relative;
        top: 70px;
        margin: auto;
        // border: solid 1px rgba(000,000,000, .2);
        // background: rgba(255,255,255, 1);
        // border-radius: 100px;
        padding: 25px;
        padding-top: 15px;
        z-index: 1;
      img {
        width: 100px;
				// backdrop-filter: blur(7px);
        // opacity: .1;
      }
    }

    }
}
/* Contact Section */

/* Footer Section*/
#footer {
	background: #f6f6f6;
	padding: 30px 0;
}
#footer p {
	color: #888;
	font-size: 14px;
}
#footer a {
	color: #608dfd;
}
#footer a:hover {
	border-bottom: 2px solid #608dfd;
}

#contractaddress{
	color:white;
}

.btn-chart{
	margin-left:10px!important;
}

.token {
  // color: $grey_1;
  // position: relative;
  // display: block;
  // opacity: .5;
  // font-size: 1rem;
  // left: 50px;
  // top: -10px;
}


.container.full-width {
	width: 100%;
	max-width: 100%;;
	padding: 100px 50px 200px 50px;
	margin: 0;
}