@import '../../scss/colors';

body {
  // color: $black_2;
  font-family: 'Montserrat', sans-serif !important;
  background-color: $grey_2 !important;
  font-size: 16px;
}
.ag-theme-alpine {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  font-family: 'Montserrat', sans-serif !important;
  background: #ebebeb;
}
.ag-root-wrapper {
  min-height: 100vh !important;
}
.logoText {
  font-family: 'Montserrat', sans-serif;
  color: #ffffff;
  margin-left: 15px;
  font-weight: 800;
  letter-spacing: .25rem;
}
.origin-top-right {
  z-index: 999;
}
.container-fluid.mt100 {
  padding-right: var(--bs-gutter-x, 0rem);
  padding-left: var(--bs-gutter-x, 0rem);
}
.container-fluid main {
  // background-color: #08315c;
  width: 100%;
  max-width: 100%;
  padding-right: var(--bs-gutter-x, 0rem);
  padding-left: var(--bs-gutter-x, 0rem);
}

// .ico {
//   color: #fff;
//   h1, .token, .about-text h4 {
//     color: #fff;
//   }
// }

.container.main-container {
  // background-color: #08315c;
  width: 100%;
  max-width: 100%;
  padding-right: var(--bs-gutter-x, 0rem);
  padding-left: var(--bs-gutter-x, 0rem); 
  // &#ico {
  //   padding: 200px 50px 200px 50px;
  // }
}

h2 {
  small {
    display: block;
    font-size: 0.6em;
    padding-top: 20px;
    // text-transform: capitalize;
  }
}

a {
  text-decoration: none;;
}

input:focus {
  border: solid 1px #a2a4a5 !important;
  box-shadow: none !important;
  transition: all .25s;
}
.card {
  border-radius: 6px !important;
}

#news {
  &.row {
    display: flex;
    width: 100%;
  }
  .card {
    flex: 1;
  }

  .cats {
    font-size: 11px;
    background: $green-default;
    padding: 3px 6px;
    margin: 0 5px 5px 0;
    border-radius: 4px;
    font-weight: 900;
    }
  .tags {
    font-size: 11px;
    color: #fff;
    background: $chart-triadblue-1;
    padding: 3px 6px;
    margin: 0 5px 5px 0;
    border-radius: 4px;
    font-weight: 900;
  }
  .card-text {
    margin-top: 15px;
  }
}


// BUTTON COLORS

.btn {
  border-radius: 6px !important;
  padding-right: 35px;
    .fa-solid {
      position: relative;
      margin-top: 4px;
      opacity: .75;
      left: 0px;
      transition: all 0.8s;
    }
    &:hover {
        
      }
  }
.btn-success {
  background-color: $green-default;
  border-color: $green-default;
  color: $black_2;
  &:hover {
    background-color: $green-default-hover;
    border-color: $green-default-hover;
    // color: $black_2;
  }
}

h3.h3 {
  text-transform: uppercase;
  margin: 0 0 40px 0;
  font-weight: 800;
  font-size: 20px;
  a {
    position: relative;
    top: -3px;
    left: 10px;
    color: $black-1;
    font-weight: 700;
    // font-size: 26px;
    // background: $blue-1;
    border-radius: 6px;
    padding: 0px 25px;
    padding-bottom: 5px;
  }
  span {
    position: relative;
    top: 2px;
    font-size: 26px;
    font-style: normal;
  }
}
