// Global styles for all charts
// .mc-card .card {
//   padding: 15px;
//   background-image: linear-gradient(335deg, rgba(0,1,1,0.0) 25%, rgba(0,125,161,0.35) 75%);
//   height: 100%;
//   border: none;
//   max-height: 500px;
//   transition: background-image 1s;
//   &:hover {
//     background: linear-gradient(335deg, rgba(0,1,1,0.0) 25%, rgba(0,125,161,0.55) 75%);
//     transition: background-image 1s;
//   }
// }

.mc-card .card {
  padding: 15px;
  height: 100%;
  position: relative;
  border: none;
  // max-height: 500px;
  background-image: linear-gradient(335deg, rgba(0,1,1,0.25) 25%, rgba(0,125,161,0.25) 75%);
  z-index: 1;
  cursor: default;
  border-radius: 5px;
}

.mc-card .card::before {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(335deg, rgba(0,125,161,0.1) 25%, rgba(0,125,161,0.35) 75%);
  z-index: -1;
  transition: opacity 0.5s linear;
  opacity: 0;
}
.mc-card .card:hover::before {
  // box-shadow: 2px 2px 5px #363636;
  // border: solid 1px #000000;
  opacity: 1;
}

.highcharts-background {
  fill: transparent !important;
}
//Pie chart values
.highcharts-data-label {
  span {
    font-family: 'Roboto Mono', monospace !important;
    font-size: 13px !important;
    color: white !important;
    border: none !important;
  }
}
.highcharts-data-label, text.highcharts-data-label {
  tspan, text {
    font-size: 1.2rem !important;
    fill: #ffffff !important;
    stroke: none !important;
    stroke-width: 0 !important;
    font-weight: bold !important;
    overflow: visible !important;
    font-family: 'Roboto Mono', monospace !important;
  }
}

.highcharts-axis-labels {
  text {
    font-family: 'Roboto Mono', monospace !important;
  }
}

// XPDs Charts
// .viewXpds {
//   .mc-card .card {
//     max-height: 400px !important;
//   }
//   .highcharts-root, .highcharts-container  {
//     max-height: 200px !important;
//     // margin-top: 0px !important;
//   }
//   .highcharts-data-label {
//     span {
//       top: -40px !important;
//     }
//   }
// }