input {
  border-radius: 6px !important;
  &:focus, &:active {
    border-color: #333333 !important;
    box-shadow: none !important;
  }
}.register__textBox {
  font-size: 18px;
  margin-bottom: 10px;
  padding: 10px;
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.675rem;
  line-height: 1.5;
  border-radius: 0rem;
}
.register__container {
  background-color: #dcdcdc;
  display: flex;
  flex-direction: column;
  padding: 30px;
  text-align: center;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

textarea:focus, input[type="text"]:focus, input[type="password"]:focus, input[type="datetime"]:focus, input[type="datetime-local"]:focus, input[type="date"]:focus, input[type="month"]:focus, input[type="time"]:focus, input[type="week"]:focus, input[type="number"]:focus, input[type="email"]:focus, input[type="url"]:focus, input[type="search"]:focus, input[type="tel"]:focus, input[type="color"]:focus, .uneditable-    input:focus {
  border-color: none !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  outline: -webkit-focus-ring-color auto 0px !important;
}