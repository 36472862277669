.ico {
  background-color: rgba(8, 49, 92, .8) !important;
  color: #fff;
  padding: 100px 50px 100px 50px;

  .card .card-body {
    color: rgba(0, 0, 0, 0.8) !important;
  }
  h2 {
    color: #fff !important;
    margin-bottom: 15px;
    padding-bottom: 15px;
  }
  .countDown .card {
    margin: 20px;
    text-align: center;
  }
  .llama-font {
    font-style: unset;
    display: block;
    // font-size: 1.25rem;
    text-align: center;
    font-size: 38px;
    margin-bottom: 20px;
    transition: all 0.5s;
    color: #fff;
    width: 75px;
    height: 75px;
    margin: 0 auto 20px auto;
    padding: 10px 0;
    border-radius: 50%;
    background: #a2cc39;
    // box-shadow: 10px 10px 10px rgb(0 0 0 / 5%);
  }

  .social {
    ul li {
      display: inline-block;
      margin: 0 20px;
    }
    i {
      &:hover {
        background-color: #fff;
      }
    &.fab {
        text-align: center;
        font-size: 22px;
        width: 48px;
        height: 48px;
        padding: 12px 0;
        border: 2px solid #b7b7b7;
        color: #b7b7b7;
        border-radius: 50%;
        transition: all 0.3s;
        &:hover {
          border: 2px solid #fff;
          color: #08315c;
        }
      }
    }
  }
  .ContactLogo {
    div {
      width: 250px;
      margin: 0 auto;
    img {
      width: 100%;
      max-width: 150px;
      transform: scaleX(-1);
    }
    }
  }
}
.ico {
  color: #fff;
  h1, .token, .about-text h4, h4 {
    color: #fff;
  }
  .card, .card-body {
    font-size: .9rem;
    font-weight: bold;
    // color: rgba(0, 0, 0, 0.8) !important
  }

  // .card {
  //   // margin-top: 25px;
  // }
  .col-md-4 {
    display: table;
    .card {
      display: table-cell;
      background:#08315c;
      color: #fff;
      transition: all .25s;
      border: none;
      cursor: pointer;
      &:hover {
        background: rgba(255,255,255, .2) !important;
        transition: all .25s;
        color: #fff;
        // color: rgba(0,0,0, .85) !important;
        
      }
    }
  }
}