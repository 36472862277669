@import '../../../scss/colors';

.navbar-brand {
  .logo {
    width: 50px;
    position: relative;
    // top: -8px;
  }
  .logo-text {
    color: #fff !important;
  }
  padding: 0 !important;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 1) !important;
  border: none !important;
  box-shadow: none !important;
  &.collapsed {
    border: none !important;
  }
}
.navbar-collapse.collapse.show {
  margin-top: 10px;
  border-top: solid 1px rgba(255, 255, 255, 0.1);
}
.navbar-text {
  position: relative;
  // left: 1px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
  letter-spacing: .15rem;
    a{
      color: #0000009d !important;
      text-decoration: none !important;
      &:hover {
        color: #ffffff !important;
      }
    }
  // top: -3px;
}
.dropdown-toggle.nav-link, .nav-link {
  color: #c4c2c2 !important;
  font-size: 1rem;
  text-align: right;
  padding-right: 15px !important;
  &:hover {
    color: #fff !important;
  }
}
.logo-text {
  padding-left: 0;
  color: #fff !important;
}
.navbar-text a {
  color: #ffffff !important;
}
span.ml10.mr10.mt7.bold {
  display: none;
}
.mainNav {
  background-color: #08315c !important;
  transition: all 1s ease;
  padding-bottom: 0;
  .nav-link a, .nav-text a {
        transition: all .5s ease;
        color: #ffffff !important;
  }
  a.nav-login {
    background: $blue-link;
    color: $white_1 !important;
    &:hover {
      color: $grey_2 !important;
    }
  }
  .container {
    border-bottom: solid 1px rgba(255,255,255,.1);
    padding-bottom: 10px;
  }
  &.stick {
      // background-color: #ffffff !important;
      padding-bottom: 0;
      position: fixed;
      width: 100%;
      top: 0;
      z-index: 999;
      // background-color: #08315c !important;
      transition: all .5s ease;
      .nav-link, .navbar-text {
        transition: all .5s ease;
        // color: #08315c !important;
        text-decoration: none;
      }
    }
}

.breadcrumbs {
  position: relative;
  font-size: .75rem;
  z-index: 9999;
  a {
    text-decoration: none;;
  }
}

.ContactLogo {
  padding: 25px;
  border: solid 1px;
  border-radius: 500px;
  height: 300px;
  width: 300px;
  padding-top: 60px;
  padding-left: 70px;
}