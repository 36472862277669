@import '../../../../scss/colors';

#welcome {
  background-color: #08315c;
  color: #fff;
  padding: 50px 10px;

  .logo-section {
    min-height: 300px;
    background: radial-gradient(circle, hsla(0, 0%, 100%, 1) 0%, hsla(0, 0%, 92%, 1) 44%, hsla(0, 0%, 87%, 1) 71%, hsla(0, 0%, 73%, 1) 100%);
    border-radius: 6px;
  }
  .logo-section-2 {
    display: flex; 
    align-items: center;
    min-height: 200px;
    // background: $grey_2;
    color: $black_2;
    padding: 25px;
    line-height: 2rem;
    font-weight: bold;
    background: hsla(0, 0%, 73%, 1);
    text-align: center;
    border-radius: 6px;;

background: radial-gradient(circle, hsla(0, 0%, 73%, 1) 0%, hsla(0, 0%, 100%, 1) 100%);

background: -moz-radial-gradient(circle, hsla(0, 0%, 73%, 1) 0%, hsla(0, 0%, 100%, 1) 100%);

background: -webkit-radial-gradient(circle, hsla(0, 0%, 73%, 1) 0%, hsla(0, 0%, 100%, 1) 100%);

// filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#BBBBBB", endColorstr="#FFFFFF", GradientType=1 );
  }
  .row-2 {
    margin-top: 75px;
  }
  .text-section {
    color: $grey_1;
    padding: 0px 100px 0;
    h3 {
      margin-bottom: 50px;
      font-weight: 500;
      line-height: 2rem;
      padding: 0 25px;
    }
    .btn {
      height: 200px;
      display: flex; 
      align-items: center;
      justify-content: center;
      border-radius: 6px !important;
    }
  }

  .main-intro {
    // background: #fff;
    // padding: 25px;
    margin-bottom: 100px;
  }
  p {
    &.text {
      font-size: 1.25rem;
      .highlight {
        font-size: 1.5rem;
        font-weight: 600;
        margin-bottom: 25px;
      }
      .sub {
        opacity: .75;
        span {
          display: block;
          margin-bottom: 20px;
        }
        .btn {
          // display: block;
          width: 200px;
          margin-top: 25px;
          background-color: radial-gradient(176.24% 995.34% at -76.24% 151.33%, rgb(125, 66, 251) 0%, rgb(46, 214, 225) 100%) !important;;
          transition: all 0.8s;
          &:hover {
            background-color: radial-gradient(176.24% 995.34% at -76.24% 151.33%, rgb(46, 214, 225) 0%, rgb(125, 66, 251) 100%) !important;;
            transition: all 0.4s;
            .fa-solid {
              margin-left: 20px;
              transition: all 0.4s;
            }
          }
        }
      }
    }
    .fa-solid {
      position: absolute;
      margin-top: 4px;
      opacity: .75;
      margin-left: 10px;
      transition: all 0.8s;
    }
}

  .card .card-body {
    color: rgba(0, 0, 0, 0.8) !important;
  }
  h1{
    font-family: "Raleway", sans-serif;
    color: $grey_2;
    // margin-bottom: 15px;
    // padding-bottom: 15px;
    text-transform: uppercase;
    margin:0;
    margin-top: 35px;
    // font-weight: 800;
    // font-size: clamp(3rem, 9.5vw, 7.25rem);
    font-size: 5rem;
    animation: colorchange 3s ease-in;
  }
  .fa-arrow-down {
    color: $white_1;
    position: absolute;
    margin-left: 50px;
    margin-top: 15px;
    font-size: 6rem;
  }
  .countDown .card {
    margin: 20px;
    text-align: center;
  }
  .llama-font {
    font-style: unset;
    display: block;
    // font-size: 1.25rem;
    text-align: center;
    font-size: 38px;
    margin-bottom: 20px;
    transition: all 0.5s;
    // color: #fff;
    width: 75px;
    height: 75px;
    margin: 0 auto 20px auto;
    padding: 10px 0;
    border-radius: 50%;
    background: #a2cc39;
    // box-shadow: 10px 10px 10px rgb(0 0 0 / 5%);
  }

  .social {
    ul li {
      display: inline-block;
      margin: 0 20px;
    }
    i {
      &:hover {
        background-color: #fff;
      }
    &.fab {
        text-align: center;
        font-size: 22px;
        width: 48px;
        height: 48px;
        padding: 12px 0;
        border: 2px solid #b7b7b7;
        color: #b7b7b7;
        border-radius: 50%;
        transition: all 0.3s;
        &:hover {
          border: 2px solid #fff;
          color: #08315c;
        }
      }
    }
  }
  .ContactLogo {

    div {
      width: 250px;
      margin: 0 auto;
    img {
      width: 100%;
      max-width: 150px;
      transform: scaleX(-1);
    }
    }
  }

  .llamaCircle {
    width: 250px;
    height: 250px;
    // background: #fbebc8;
    background: radial-gradient(circle, rgba(40,64,103, .5) 0%, rgba(40,64,103, 1) 65%);
    position: relative;
    margin: 100px auto;
    margin-bottom: 0;
    margin-top: 0;
    border-radius: 150px;
    border: solid 3px #4e6893;
  }

  .llamaStar {
    width: 200px;
    height: 200px;
    background-color: #ecf2ff;
    // background: rgba(8, 49, 92, .5) !important;
    position: relative;
    margin: 100px auto;
    margin-top: 0;
    // box-shadow: 5px 0px 10px 10px #fff;
  }
  .llamaStar:before {
    content: "";
    position: absolute;
    width: 200px;
    height: 200px;
    background-color: #ecf2ff;
    // background: rgba(8, 49, 92, .5) !important;
    transform: rotate(30deg);
    // box-shadow: 5px 0px 10px 10px #fff;
  }
  .llamaStar:after {
    content: "";
    position: absolute;
    width: 200px;
    height: 200px;
    background-color: #ecf2ff;
    // background: rgba(8, 49, 92, .5) !important;
    transform: rotate(60deg);
    // box-shadow: 5px 0px 10px 10px #fff;
  }
  .llamaLogo {
    max-width:50%;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.ico {
  color: #fff;
  h1, .token, .about-text h4, h4 {
    color: #fff;
  }
  .card, .card-body {
    font-size: .9rem;
    font-weight: bold;
    // color: rgba(0, 0, 0, 0.8) !important
  }

  // .card {
  //   // margin-top: 25px;
  // }
  .col-md-4 {
    display: table;
    .card {
      display: table-cell;
      background:#08315c;
      color: #fff;
      transition: all .25s;
      border: none;
      cursor: pointer;
      &:hover {
        background: rgba(255,255,255, .2) !important;
        transition: all .25s;
        color: #fff;
        // color: rgba(0,0,0, .85) !important;
        
      }
    }
  }
}

.purchase {
  li, a {
    font-weight: bold;
    padding-bottom: 15px;
  }
}

@keyframes colorChange {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}