@import '../../../../scss/colors';

.presale {
  // background-color: rgba(8, 49, 92, .9) !important;
  color: $black_2;
  padding: 150px 50px 100px 50px;

  .table-token {
    // color: #fff;
    background: rgba(255,255,255, .9);
    border-radius: 6px;
    td {
      padding: 15px 20px;
      border-bottom: none;
    }
    .token-eth {
      font-size: .85rem;
      // font-weight: 500;
    }
  }

  .progressBar {
    position: relative;
    width: 100%;
    height: 20px;
    border-radius: 4px;
    background: rgba(22, 163, 254, 0.2);
    overflow: visible;
    margin:25px 0 55px;
    .progress-point {
      color: #8ca2e5;
      font-size: 11px;
      line-height: 14px;
      font-weight: 500;
      text-transform: uppercase;
      position: absolute;
      top: 30px;
      transform: translateX(-50%);
      padding: 3px 0;
      white-space: nowrap;
      &:after {
        position: absolute;
        bottom: 100%;
        left: 50%;
        margin-left: -1px;
        content: '';
        width: 2px;
        height: 40px;
        background: rgba(193, 206, 241, 0.5);
    }
    }
    .progress-percent {
      border-radius: 4px;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      background: #16a3fe;
    }
    .progress-point-1 {
      left: 25%;
    }
    .progress-point-2 {
      left: 85%;
    }
  }

  .bonus {
    // border-radius: 0 0 8px 8px;
    // border: 1px solid rgba(22, 163, 254, 0.2);
    // border-top: none;
    display: flex;
    // &.bg-theme {
    //   background-color: #122272 !important;
    // }
    // .bonus-info:not(:last-child) {
    //   /* border-right: 1px solid rgba(22, 163, 254, 0.2); */
    // }
    .bonus-info {
      position: relative;
      width: 50%;
      padding: 12px 16px 16px;
    }
    .bonus-percent {
      font-size: 28px;
    }
    .bonus-date {
      font-size: 12px;
      color: #8ca2e5;
    }
    .bonus-badge {
      position: relatve;
      top: 15px;
      right: 15px;
      text-transform: uppercase;
      border-radius: 11px;
      line-height: 15px;
      font-size: 8px;
      font-weight: 500;
      padding: 0 8px;
      color: #fff;
      background: #23c99d;
      // top: 50%;
      right: 0;
      // transform: translateY(-50%) rotate(-90deg);
      line-height: 22px;
      font-size: 11px;
      padding: 0 10px;
      &.grey {
        background: grey;
      }
    }
  }

  .card .card-body {
    color: rgba(0, 0, 0, 0.8) !important;
    padding: 1rem .5rem;
  }
  h2 {
    color: $black_2 !important;
    margin-bottom: 15px;
    padding-bottom: 15px;
  }
  .countDown .card {
    margin: 10px;
    text-align: center;
  }
  .llama-font {
    font-style: unset;
    display: block;
    // font-size: 1.25rem;
    text-align: center;
    font-size: 38px;
    margin-bottom: 20px;
    transition: all 0.5s;
    color: #fff;
    width: 75px;
    height: 75px;
    margin: 0 auto 20px auto;
    padding: 10px 0;
    border-radius: 50%;
    background: #a2cc39;
    // box-shadow: 10px 10px 10px rgb(0 0 0 / 5%);
  }

  .social {
    ul li {
      display: inline-block;
      margin: 0 20px;
    }
    i {
      &:hover {
        background-color: #fff;
      }
    &.fab {
        text-align: center;
        font-size: 22px;
        width: 48px;
        height: 48px;
        padding: 12px 0;
        border: 2px solid #b7b7b7;
        color: #b7b7b7;
        border-radius: 50%;
        transition: all 0.3s;
        &:hover {
          border: 2px solid #fff;
          color: #08315c;
        }
      }
    }
  }
  .ContactLogo {
    div {
      width: 250px;
      margin: 0 auto;
    img {
      width: 100%;
      max-width: 150px;
      transform: scaleX(-1);
    }
    }
  }

  h3 {
    a {
      border-radius: 6px !important;
    }
  }
}

.modal-title.h4 {
  height: unset !important;
}

.ico {
  color: #fff;
  h1, .token, .about-text h4, h4 {
    color: #fff;
  }
  .card, .card-body {
    font-size: .9rem;
    font-weight: bold;
    // color: rgba(0, 0, 0, 0.8) !important
  }

  // .card {
  //   // margin-top: 25px;
  // }
  .col-md-4 {
    display: table;
    .card {
      display: table-cell;
      background:#08315c;
      color: #fff;
      transition: all .25s;
      border: none;
      cursor: pointer;
      &:hover {
        background: rgba(255,255,255, .2) !important;
        transition: all .25s;
        color: #fff;
        // color: rgba(0,0,0, .85) !important;
        
      }
    }
  }
}
