.navbar-brand {
  .logo {
    width: 35px;
    position: relative;
    // top: -8px;
  }
  padding: 0 !important;
}
.footer {
  background-color: #08315c !important;
  transition: all 1s ease;
}
.navbar-text {
  position: relative;
  // left: 1px;
  font-family: 'Montserrat', sans-serif;
  color: #ffffff !important;
  font-weight: 800;
  letter-spacing: .15rem;
  // top: -3px;
}
.dropdown-toggle.nav-link, .nav-link {
  // color: $black_2 !important;
}

.social {
  ul li {
    display: inline-block;
    margin: 0 20px;
  }
  i {
    &:hover {
      background-color: #fff;
    }
  &.fab {
      text-align: center;
      font-size: 22px;
      width: 48px;
      height: 48px;
      padding: 12px 0;
      border: 2px solid #b7b7b7;
      color: #b7b7b7;
      border-radius: 50%;
      transition: all 0.3s;
      &:hover {
        border: 2px solid #fff;
        color: #08315c;
      }
    }
  }
  li.footerLogo {
    position: relative;
    top: -2px;
    float: left;
    img {
      width: 40px;
    }
  }
}

#disclaimer p {
  font-size: .8rem;
}