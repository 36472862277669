#team {
  margin-top: 0 !important;
  background-color: rgba(8, 49, 92, .2);
  padding: 100px 50px 100px 50px !important;
  color: rgba(8, 49, 92, 1);
}
#team .team-img {
	width: 240px;
}
#team .thumbnail {
	background: transparent;
	border: 0;
}
#team .thumbnail .caption {
	padding: 10px 0 0 0;
	color:  rgba(8, 49, 92, 1);
} 