@media (max-width: 576px) {
  .mainNav {

    &.stick {
      position: fixed;
      width: 100%;

      // .navbar-toggler {
        // margin-right: 20px;
      // }
    }
  }

  .mr35 {
    margin-right: 0 !important
  }

  #welcome {

    h1,
    .fa-arrow-down {
      font-size: 2rem;
      margin-top: 0;
    }

    .main-intro {
      margin-bottom: 20px;
    }

    .row-2 {
      margin-top: 0 !important;
    }

    .text-section {
      padding: 25px 10px 0 10px;
    }

    h3 {
      margin-bottom: 25px !important;
    }

    .logo-section-2 {
      margin-top: 25px;
    }
  }

  #presale {
    .table-token {
      font-size: .8rem;

      .token-eth {
        font-size: .7rem;
        ;
      }
    }

    h3 {
      margin-bottom: 25px !important;
      text-align: center;
      ;

      a {
        left: 0;
        top: 10px !important;
        width: 100%;
      }
    }

    .bonus-tokens {
      margin-top: 65px;

      h3 {
        padding-bottom: 20px;
        margin-bottom: 20px;
      }
    }

    .bonus {
      margin-top: 40px;
      border-top: solid 1px rgba(0, 0, 0, 0.1);
    }

    .card-text {
      font-size: .75rem;
    }

    .next-section {
      margin-top: 25px;
      text-align: center;
    }
  }

  #about {
    .col-md-4 {
      margin-bottom: 25px;
    }

    .about-btn {
      margin-top: 25px;
    }
  }

  .one,
  #welcome,
  .presale,
  #about,
  #contact,
  #roadmap,
  #team {
    padding: 50px 10px !important;
  }

  .navbar-light {
    .navbar-toggler {
      border-color: transparent;

      .navbar-toggler-icon {
        color: rgba(255, 255, 255, 1);
      }
    }
  }

  .one {
    padding: 50px 10px 50px 10px;

    h1 {
      font-size: 26px;
    }

    p.text {
      .highlight {
        .highlight {
          font-size: 1.15rem;
        }
      }

      .sub {
        font-size: 1.15rem;

        .ml15 {
          margin-left: 0 !important;
        }
      }
    }
  }

  .social ul li {
    margin: 0 10px;

    &.footerLogo {
      top: -7px;
    }

    i.fab {
      font-size: 15px;
      width: 40px;
      height: 40px;

      &:before {
        position: relative;
        top: -1px;
      }
    }
  }

  #tokens {
    padding: 100px 0px 100px 0px !important;
    .mt100 {
      margin-top: 0 !important;
    }
    .row {
      height: auto !important;
    }
  }

  #roadmap {

    .roadmap {
      margin-bottom: 25px;
    }
  }
}


@media (min-width: 768px) {

  .mainNav {

    // padding-bottom: 0;
    // position: fixed;
    // width: 100%;
    // top: 0;
    // z-index: 999;
    &.stick {
      position: fixed;
      // top: 55px;
      width: 100%;

      .navbar-toggler {
        margin-right: 20px;
      }
    }
  }

  .mr35 {
    margin-right: 0 !important
  }

  // &.stick {
  //   top: 55px !important;
  //   .navbar-toggler {
  //     margin-right: 20px;
  //   }
  // }
  #welcome {

    h1,
    .fa-arrow-down {
      font-size: 2rem;
      margin-top: 0;
    }

    .main-intro {
      margin-bottom: 20px;
    }

    .row-2 {
      margin-top: 0 !important;
    }

    .text-section {
      padding: 25px 10px 0 10px;
    }

    h3 {
      margin-bottom: 25px !important;
    }

    .logo-section-2 {
      margin-top: 25px;
    }
  }

  #presale {
    .table-token {
      font-size: .8rem;

      .token-eth {
        font-size: .7rem;
        ;
      }
    }

    h3 {
      margin-bottom: 25px !important;
      text-align: center;
      ;

      a {
        left: 0;
        top: 10px !important;
        width: 100%;
      }
    }

    .bonus-tokens {
      margin-top: 65px;

      h3 {
        padding-bottom: 20px;
        margin-bottom: 20px;
      }
    }

    .bonus {
      margin-top: 40px;
      border-top: solid 1px rgba(0, 0, 0, 0.1);
    }

    .card-text {
      font-size: .75rem;
    }

    .next-section {
      margin-top: 25px;
      text-align: center;
    }
  }

  #about {
    .col-md-4 {
      margin-bottom: 25px;
    }

    .about-btn {
      margin-top: 25px;
    }
  }

  .one,
  #welcome,
  .presale,
  #about,
  #contact,
  #roadmap,
  #team {
    padding: 50px 10px !important;
  }

  .navbar-light {
    .navbar-toggler {
      border-color: transparent;

      .navbar-toggler-icon {
        color: rgba(255, 255, 255, 1);
      }
    }
  }

  .one {
    padding: 50px 10px 50px 10px;

    h1 {
      font-size: 26px;
    }

    p.text {
      .highlight {
        .highlight {
          font-size: 1.15rem;
        }
      }

      .sub {
        font-size: 1.15rem;

        .ml15 {
          margin-left: 0 !important;
        }
      }
    }
  }

  .social ul li {
    margin: 0 10px;

    &.footerLogo {
      top: -7px;
    }

    i.fab {
      font-size: 15px;
      width: 40px;
      height: 40px;

      &:before {
        position: relative;
        top: -1px;
      }
    }
  }

  #tokens {
    padding: 100px 0px 100px 0px !important;
    .mt100 {
      margin-top: 0 !important;
    }
    .row {
      height: auto !important;
    }
  }

  #roadmap {

    .roadmap {
      margin-bottom: 25px;
    }
  }

  #welcome {

    h1,
    .fa-arrow-down {
      font-size: 2.5rem;

    }
  }
}

@media (min-width: 992px) {
  #welcome {

    h1,
    .fa-arrow-down {
      font-size: 5rem;

    }
  }

}

@media (min-width: 1200px) {}

@media (min-width: 1400px) {}