@import './colors';

.btn {
  border-color: transparent !important;
  border-radius: 6px !important;
  transition: all 0.4s;
  &:focus, &:active {
    border-color: transparent !important;
    box-shadow: none !important;
  }
  &:hover {
    .fa-solid {
      left: 20px;
      transition: all 0.4s;
    }
  }
  &.btn-primary {
    background-color: $blue_default-2 !important;
    color: $white_1 !important;
    &:hover {
      background-color: $blue_1 !important;
    }
  }

}

