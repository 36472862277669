@import '../../scss/colors';

.blog-posts {
  margin-top: 25px;
  // display: grid;
  // grid-auto-flow: column;
  // display: flex;
  // flex-wrap: wrap;
  // align-items: stretch;
  .card {
    color: $black_2;
    overflow: hidden;
    height: 100%;
    .card-text {
      margin-bottom: 80px;
    }
    h2.card-title {
      font-size: 1.25rem;
    }
    p {
      font-size: .85rem !important;
      line-height: 1.5rem;
    }
    .card-img-top {
      transition: all .75s;
      height: 250px;
      width: auto;
    }
    &:hover {
      .card-img-top {
        transform: scale(1.025);
        transition: all .75s;
        height: 250px !important;
        overflow:hidden;
      }
      .fa-solid {
        left: 20px;
        transition: all 0.4s;
      }
    }
    .btn {
      position: absolute;
      bottom: 25px;
    }
  }
}

.blog-post {
  h1 {
    font-family: 'Raleway', sans-serif;
    font-weight: 800;
    text-transform: uppercase;
    margin-bottom: 25px;
  }
  h2 {
    font-weight: 600;
  }
  img {
    max-width: 100%;
    margin-bottom: 25px;
  }
}