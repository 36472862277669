@import '../../scss/colors';

.coinList {
  height: 100%;
  margin-top: -47px;
  font-weight: normal !important;
  a {
    position: relative;
    text-decoration: none;
    // font-size: 12px;
    top: -6px;
    display: block;
    padding-bottom: 0;
  }
  span.ml4 {
    position: relative;
    font-size: 10px;
    top: -32px;
    left: 35px;
  }
  .ag-floating-filter-button {
    display: none;
  }
  .btn-xsm {
    padding: 2px 5px;
    font-size: 11px;
  }
}
.btn-xsm {
  padding: 2px 5px;
  font-size: 11px;
}
.CardDetails {
  position: absolute;
  .card-title span {
    font-size: 12px;
    float: right;
    color: #cccccc;
    margin-top: -5px;
  }
}

.red {
  color: $red-1;
}
.green {
  color: green;
}

.GridHeader {
  background: #f8f8f8;
    border: transparent;
    border-bottom: none;
    height: 65px;
    .col {
      border-bottom: solid 1px rgba(99, 99, 99, 0.3);
    }
  .col, .col-3 {
    padding: 15px 15px 0 15px;
  }
  span {
    position: relative;
    margin-right: 15px;
    top: 6px;
    font-size: 16px;
  }
}
.ag-header-container {
  background-color: #f8f8f8 !important;
}

.chartdates {
  margin-bottom: 20px;
  .nav-link{
    font-size: 14px !important;
    font-weight: bold;
    padding: 0 10px;

    &.active {
      background-color: #fff !important;
      color: rgb(53, 53, 53) !important;
    }
  }
}

span.loading {
  position: relative;
  font-size: 11px;
  color: #ccc;
  top: 7px;
}
#QuickFilter {
  &:focus {
    border-color: #bababa !important;
  }
}

a.toplist {
  text-decoration: none;
  color: #424242 !important;

  .card {
    background-color: #f8f8f8 !important;
    transition: all .55s;
    color: rgba(0,0,0,.5);
    font-size: .85rem;
    .card-title {
      font-size: .85rem;
      font-weight: 800;
      background: none;
      color: rgba(0,0,0,1);
      padding: 0;
    }
    &:hover {
      background-color: #ffffff !important;
    }
  }

  &:hover .card {
    border: solid 1px rgba(99, 99, 99, 0.3);
    box-shadow: rgba(99, 99, 99, 0.3) 0px 2px 8px 0px;
    transition: all .55s;
    // border: solid 1px #000 !important;
  }
  transition: all .55s;
}

.ag-theme-alpine .ag-row, .ag-header, .ag-theme-alpine .ag-root-wrapper {
  background-color:  #f8f8f8 !important;
  border: transparent  !important;
  border-bottom: solid 1px rgba(99, 99, 99, 0.3) !important;
}

.ag-row {
  height: 60px !important;
  transition: all .5s !important;
  &:hover {
    background-color: #ffffff !important;
    transition: all .5s !important;
  }
}

.ag-header {
  max-width: 1294px;
}

.chartContainer {
  background: #fff;
  border-radius: 6px;
  padding: 20px;
  padding-right: 0;
}
.ag-body .ag-body-viewport {
  font-weight: bold;
}

@media (min-width: 640px) {
  .coinList.fullwidth {
    display: block;
  }
  .coinList.responsive {
    display: none;
  }
}


@media (min-width: 320px) and (max-width: 639px) {
  .coinList.fullwidth {
    display: none;
  }
  .coinList.responsive {
    display: block;
  }
}
.socialmedia {
  .llamaCircle {
    width: 150px !important;
    height: 150px !important;
    background: #fbebc8;
    background: radial-gradient(circle, rgba(255, 255, 255, 0.35) 0%, #284067 75%);
    position: relative;
    margin: 100px auto;
    margin-top: 0;
    border-radius: 150px;
    border: solid 3px #4e6893;
  }
  .llamaLogo {
    width: 100%;
    max-width: 75px !important;
    transform: scaleX(-1);
    position: relative;
    top: -225px !important;
  }
  .ag-theme-alpine {
    margin-top: -170px !important;
    overflow: hidden;
  }
}

.ag-row-hover {
  background-color: #000 !important;
  transition: all .5s !important;
}